import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';

// Contexts
import { LanguageContext, TenantContext } from '../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPLayout } from '../components/organisms/RPLayout';
import { SplitLayout } from '../components/organisms/SplitLayout';
import { FullLayout } from '../components/organisms/FullLayout';
import { ErrorBoundary } from '../components/organisms/ErrorBoundary';
import RPGoogleHeaderScript from '../components/organisms/RPGoogleHeaderScript';
import RPGoogleBodyScript from '../components/organisms/RPGoogleBodyScript';
import LandingPage from '../components/pages/LandingPage';
import { RegistrationLandingPage } from '../components/pages/RegistrationLandingPage';
import { Registration } from '../components/pages/Registration';
import { SkipDocsPage } from '../components/pages/SkipDocsPage';
import { KYCPass } from '../components/pages/KYCPass';
import { RegistrationFail } from '../components/pages/RegistrationFail';
import { GenericError } from '../components/pages/GenericErrorPage';
import { DocUploadCompletePage } from '../components/pages/DocUploadCompletePage';
import { DocumentUploaded } from '../components/pages/DocumentUploaded';
import { DocumentUploading } from '../components/pages/DocumentUploading';
import { PageNotFound } from '../components/pages/PageNotFound';
import MaintenancePage from '../components/pages/MaintenancePage';
import { RegistrationReturn } from '../components/pages/RegistrationReturn';
import Landing from '../components/pages/Landing';
import Register from '../components/pages/Register';
import RPHelpSection from '../components/organisms/RPHelpSection';
import ReferAFriend from '../components/pages/ReferAFriend';
import { CFSBTestingPage } from '../components/pages/CFSBTestingPage'
import RegistrationReturnInactiveDocUploadComplete from '../components/pages/RegistrationReturnInactiveDocUploadComplete';
import RegistrationReturnInactiveKycPassPage from '../components/pages/RegistrationReturnInactiveKycPassPage';
// Types
import { OrganizationCodeTypes } from '../core/types/OrganizationTypes';
// RTK Slice
import {
  getRegistrationDetails,
  resetBurgerMenuState,
  resetMobileHeaderState
} from '../redux/modules/registrationDetailsSlice';
// Utils
import checkNavigationFromLandingPage from '../core/utils/CheckNavigationFromLandingPage';
import { initCoraLogix } from '../core/utils/Coralogix';
import {
  FROM_LANDING_PAGE_COOKIE_NAME,
  LANG_CODE_MAPPING,
  LIVE_CHAT_AVAILABILITY_ORGANIZATIONS_LIST,
  ORGANIZATION_CODE_MAPPING,
  SESSION_EXPIRED_LS_KEY,
  SESSION_TIMER_MODAL_LS_KEY
} from '../core/utils/Constants/Constants';
// Redux
import { RESET_STORE_TO_INITIAL_STATE } from '../redux/ActionTypeConstants';
import { resetStoreToInitialState } from '../redux/actions/resetStore';
import RPDocumentTitleAndFavicon from '../components/organisms/RPDocumentTitleAndFavicon';
// Feature Flag
import { featureFlags } from '../core/FeatureFlags/FeatureFlags';

const Main = () => {
  const { tenant } = useContext(TenantContext);
  const { language } = useContext(LanguageContext);
  const dispatch: any = useDispatch();

  const { shouldShowMobileHeader, shouldHideBurgerMenu, shouldHideLiveChatIon } = useSelector(getRegistrationDetails);

  const currentTenant = tenant ? tenant : ORGANIZATION_CODE_MAPPING.currenciesdirect;
  const isLivechatEnabled: boolean = (LIVE_CHAT_AVAILABILITY_ORGANIZATIONS_LIST.includes(
    currentTenant as OrganizationCodeTypes) && language !== LANG_CODE_MAPPING.BR
  );

  const {
    landingPage: { useReactLandingPage }
  } = featureFlags;

  useEffectOnce(() => {
    initCoraLogix();

    const isFromLandingPage: boolean = checkNavigationFromLandingPage();
    if (!useReactLandingPage && isFromLandingPage) {
      // Remove key for session-expired from local storage
      localStorage.removeItem(SESSION_EXPIRED_LS_KEY);
      // Remove key for session-timer-modal from local storage
      localStorage.removeItem(SESSION_TIMER_MODAL_LS_KEY);
      // Remove cookie which is set by setupProxy.js file
      document.cookie = FROM_LANDING_PAGE_COOKIE_NAME + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT';
      //Setting all store slices to initial state at once
      dispatch(resetStoreToInitialState(RESET_STORE_TO_INITIAL_STATE));
    }
    shouldShowMobileHeader === false && dispatch(resetMobileHeaderState());
    shouldHideBurgerMenu === true && dispatch(resetBurgerMenuState());
  });

  return (
    <BrowserRouter>
      <RPDocumentTitleAndFavicon />
      <RPGoogleHeaderScript />
      <RPGoogleBodyScript />
      <div>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<RegistrationLandingPage />} />
            <Route
              path="*"
              element={
                <RPLayout>
                  <PageNotFound />
                </RPLayout>
              }
            />
            <Route path="/landing" element={<LandingPage />} />
            <Route
              path="/landing-page"
              element={
                <RPLayout>
                  <Landing />
                </RPLayout>
              }
            />
            <Route
              path="/register-page"
              element={
                <RPLayout>
                  <Register />
                </RPLayout>
              }
            />
            <Route
              path="/refer-a-friend"
              element={
                <RPLayout>
                  <ReferAFriend />
                </RPLayout>
              }
            />
            <Route
              path="/registration/:step"
              element={
                <RPLayout>
                  <Registration />
                </RPLayout>
              }
            />
            <Route
              path="/registration-return"
              element={
                <RPLayout>
                  <RegistrationReturn />
                </RPLayout>
              }
            />
            <Route
              path="/registration-return-inactive"
              element={
                <RPLayout>
                  <RegistrationReturn isRegistrationReturnInactive={true} />
                </RPLayout>
              }
            />
            <Route
              path="/split-layout"
              element={
                <RPLayout>
                  <SplitLayout layoutVariant="full">
                    <div>Content From Parent - Main Content Section</div>
                  </SplitLayout>
                </RPLayout>
              }
            />
            <Route
              path="/split-layout-unequal"
              element={
                <RPLayout>
                  <SplitLayout layoutVariant="partial">
                    <div>Content From Parent - Main Content Section</div>
                  </SplitLayout>
                </RPLayout>
              }
            />
            <Route
              path="/full-layout"
              element={
                <RPLayout>
                  <FullLayout />
                </RPLayout>
              }
            />
            <Route
              path="/kyc-pass"
              element={
                <RPLayout>
                  <KYCPass />
                </RPLayout>
              }
            />
            <Route
              path="/kyc-pass-return-inactive"
              element={
                <RPLayout>
                  <RegistrationReturnInactiveKycPassPage />
                </RPLayout>
              }
            />
            <Route
              path="/registration-pending"
              element={
                <RPLayout>
                  <RegistrationFail />
                </RPLayout>
              }
            />
            <Route
              path="/registration-pending-return"
              element={
                <RPLayout>
                  <RegistrationFail />
                </RPLayout>
              }
            />
            <Route
              path="/registration-pending-return-inactive"
              element={
                <RPLayout>
                  <RegistrationFail />
                </RPLayout>
              }
            />
            <Route
              path="/skip-docs"
              element={
                <RPLayout>
                  <SkipDocsPage />
                </RPLayout>
              }
            />
            <Route
              path="/skip-docs-return"
              element={
                <RPLayout>
                  <SkipDocsPage />
                </RPLayout>
              }
            />
            <Route
              path="/generic-error"
              element={
                <RPLayout>
                  <GenericError variant="errorBoundary" />
                </RPLayout>
              }
            />
            <Route
              path="/generic-error-return-inactive"
              element={
                <RPLayout>
                  <GenericError variant="errorBoundary" isRegistrationReturnInactive={true} />
                </RPLayout>
              }
            />
            <Route
              path="/error-boundary"
              element={
                <RPLayout>
                  <GenericError variant="errorBoundary" />
                </RPLayout>
              }
            />
            <Route
              path="/doc-upload-complete"
              element={
                <RPLayout>
                  <DocUploadCompletePage />
                </RPLayout>
              }
            />
            <Route
              path="/doc-upload-complete-return"
              element={
                <RPLayout>
                  <DocUploadCompletePage />
                </RPLayout>
              }
            />
            <Route
              path="/doc-upload-complete-return-inactive"
              element={
                <RPLayout>
                  <RegistrationReturnInactiveDocUploadComplete />
                </RPLayout>
              }
            />
            <Route
              path="/document-uploaded"
              element={
                <RPLayout>
                  <DocumentUploaded />
                </RPLayout>
              }
            />
            <Route
              path="/document-uploaded-return"
              element={
                <RPLayout>
                  <DocumentUploaded isRegistrationReturn={true} />
                </RPLayout>
              }
            />
            <Route
              path="/document-uploaded-return-inactive"
              element={
                <RPLayout>
                  <DocumentUploaded isRegistrationReturnInactive={true} />
                </RPLayout>
              }
            />
            <Route
              path="/document-uploading"
              element={
                <RPLayout>
                  <DocumentUploading />
                </RPLayout>
              }
            />
            <Route
              path="/document-uploading-return"
              element={
                <RPLayout>
                  <DocumentUploading isRegistrationReturn={true} />
                </RPLayout>
              }
            />
            <Route
              path="/document-uploading-return-inactive"
              element={
                <RPLayout>
                  <DocumentUploading isRegistrationReturnInactive={true} />
                </RPLayout>
              }
            />
            <Route
              path="/page-not-found"
              element={
                <RPLayout>
                  <PageNotFound />
                </RPLayout>
              }
            />
            <Route
              path="/account-error"
              element={
                <RPLayout>
                  <GenericError variant="account" />
                </RPLayout>
              }
            />
            <Route
              path="/maintenance-page"
              element={
                <RPLayout>
                  <MaintenancePage />
                </RPLayout>
              }
            />
            <Route
              path="/cfsb-testing"
              element={
                <RPLayout>
                  <CFSBTestingPage />
                </RPLayout>
              }
            />
          </Routes>
          {!shouldHideLiveChatIon && (
            <RPLayout>
              <RPHelpSection isLivechatEnabled={isLivechatEnabled} />
            </RPLayout>
          )}
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
};

export default Main;