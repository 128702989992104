import { useContext } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

// Contexts
import { LanguageContext, TenantContext } from '../../../../core/TenantProvider/contexts';
// Types
import { ConfirmingYouType } from '../../../../core/types/RegistrationStepForm';
import { OrganizationCodeTypes } from '../../../../core/types/OrganizationTypes';
// RTK Slice
import { getRegistrationDetails } from '../../../../redux/modules/registrationDetailsSlice';
// Utils
import { checkIfBrazilChangesApplicable } from '../../../../core/utils/CheckIfBrazilChangesApplicable';
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
// Constants
import { DISABLE_MOBILE_RELATED_FEATURES } from '../../../../core/utils/Constants/Constants';

// Referring to a workaround mentioned in https://github.com/jquense/yup/issues/1631
interface TestContextExtended {
  from: {
    value: ConfirmingYouType;
  }[];
}

export const validateFileSize = (file: File | null) => {
  if (file) {
    const fileSize: number = file.size / 1024 / 1024;
    return fileSize <= 10;
  }
  return false;
};

export const validateFileExtension = (file: File | null) => {
  if (file) {
    console.log("file from validation", file);
    const allowedFileTypes = ['pdf', 'jpeg', 'png', 'jpg', 'heic'];
    const fileExtension: string = file.name.substring(
      file.name.lastIndexOf('.') + 1,
      file.name.length
    );
    return allowedFileTypes.includes(fileExtension);
  }
  return false;
};

export const ValidationSchema = () => {
  const { tenant } = useContext(TenantContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { countryOfResidence } = useSelector(getRegistrationDetails);

  const { shouldApplyBrazilChanges } = checkIfBrazilChangesApplicable(tenant, countryOfResidence);
  
  const disableUseMobileAppFlow: boolean = DISABLE_MOBILE_RELATED_FEATURES.includes(tenant as OrganizationCodeTypes) || shouldApplyBrazilChanges;

  const documentUploadRequired: string = translate(
    'registration.confirmingItsYou.uploadDocs.error.required'
  );
  const poiRequired: string = translate(
    'registration.confirmingItsYou.uploadDocs.uploadDesktop.poi.docType.error.required'
  );
  const poaRequired: string = translate(
    'registration.confirmingItsYou.uploadDocs.uploadDesktop.poa.docType.error.required'
  );

  return yup
    .object().shape({
      documentUpload: yup
        .string()
        .trim()
        .test('check-disable-mobile-app-flow', documentUploadRequired, function (documentUpload) {
          return disableUseMobileAppFlow ? disableUseMobileAppFlow : documentUpload !== '';
        })
        .nullable(),

      isPoiRequired: yup.boolean(),

      poi: yup.string().when('isPoiRequired', {
        is: true,
        then: yup.string().trim().required(poiRequired).nullable(),
      }),

      poiFiles: yup.array().when(['isPoiRequired', 'poi'], {
        is: (isPoiRequired: boolean, poi: string) => {
          return isPoiRequired && poi !== ''
        },
        then: yup.array().of(
          yup
            .mixed()
            .required('file required')
          /*.test(
            'file-size-validation',
            'poi file size error',
            validateFileSize
          )*/
          // .test(
          //   'file-extension-validation',
          //   'poi file type error',
          //   validateFileExtension
          // )
        )
          .test(
            'poi-file-required',
            'all poi files required',
            function (poiFiles, context) {
              const { from } = context as yup.TestContext & TestContextExtended;

              console.log('validation schema poiFiles array test from: ', from[0].value);

              const { selectedPoiDocSides } = from[0].value;
              return poiFiles?.length === selectedPoiDocSides;
            }
          )
      })
        .nullable()
        .default(null),

      isPoaRequired: yup.boolean(),

      poa: yup.string().when('isPoaRequired', {
        is: true,
        then: yup.string().trim().required(poaRequired).nullable(),
      }),
      poaFiles: yup.array().when(['isPoaRequired', 'poa'], {
        is: (isPoaRequired: boolean, poa: string) => {
          return isPoaRequired && poa !== ''
        },
        then: yup.array().of(
          yup
            .mixed()
            .required('file required')
          /*.test(
            'file-size-validation',
            'poi file size error',
            validateFileSize
          )
          .test(
            'file-extension-validation',
            'poi file type error',
            validateFileExtension
          ),*/
        )
          .test(
            'poa-file-required',
            'all poa files required',
            function (poaFiles, context) {
              const { from } = context as yup.TestContext & TestContextExtended;

              console.log('validation schema poaFiles array test from: ', from[0].value);

              const { selectedPoaDocSides } = from[0].value;
              return poaFiles?.length === selectedPoaDocSides;
            }
          )
      })
        .nullable()
        .default(null),

    })
    .required()

};
