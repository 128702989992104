// Types
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
// Utils
import { getCurrentTenant } from '../GetCurrentTenant';
// Constants
import { BRA, TAX_STATEMENT_STEP_AVAILABILITY_ORGANIZATIONS_LIST } from '../Constants/Constants';

interface CheckIfBrazilChangesApplicableType {
  shouldApplyBrazilChanges: boolean;
}

const CheckIfBrazilChangesApplicable = (
  tenant: OrganizationCodeTypes | undefined,
  countryOfResidence: string
): CheckIfBrazilChangesApplicableType => {
  const { currentTenant } = getCurrentTenant(tenant);

  const shouldHandleBrazilChangesBrandSpecific: boolean = TAX_STATEMENT_STEP_AVAILABILITY_ORGANIZATIONS_LIST.includes(
    currentTenant as OrganizationCodeTypes
  );

  const trimmedValue: string = countryOfResidence?.trim().toLowerCase();
  const isCorBRA: boolean = trimmedValue !== '' && trimmedValue === BRA;

  const shouldApplyBrazilChanges: boolean = isCorBRA && shouldHandleBrazilChangesBrandSpecific;

  return {
    shouldApplyBrazilChanges
  };
};

export default CheckIfBrazilChangesApplicable;
