import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

import authorisationDetailsReducer from './modules/authorisationDetailsSlice';
import registrationDetailsReducer from './modules/registrationDetailsSlice';
import registrationStepsReducer from './modules/registrationStepsSlice';
import loginDetailsReducer from './modules/loginDetailsSlice';
import referenceDataReducer from './modules/referenceDataSlice';
import errorDataReducer from './modules/errorDetailsSlice';
import {
  RESET_STORE_TO_INITIAL_STATE,
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE,
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FOR_COR_BRAZIL_IN_CD_BRAND
} from './ActionTypeConstants';
import { initialState } from './initialState';

export type AppDispatch = typeof store.dispatch;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['authorisationDetails']
};

const combinedReducers = combineReducers({
  authorisationDetails: authorisationDetailsReducer,
  registrationDetails: registrationDetailsReducer,
  registrationSteps: registrationStepsReducer,
  loginDetails: loginDetailsReducer,
  referenceData: referenceDataReducer,
  errorDetails: errorDataReducer
});

export const rootReducer = (state: ReturnType<typeof combinedReducers> | undefined, action: any) => {
  if (action.type === RESET_STORE_TO_INITIAL_STATE) {
    return combinedReducers(initialState, action);
  }

  if (action.type === RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE) {
    return combinedReducers(
      {
        ...initialState,
        registrationDetails: {
          ...initialState.registrationDetails,
          hasZARModalBeenOpened:
            state?.registrationDetails.hasZARModalBeenOpened || initialState.registrationDetails.hasZARModalBeenOpened,
          stateOfCountryOfResidence:
            state?.registrationDetails.stateOfCountryOfResidence ||
            initialState.registrationDetails.stateOfCountryOfResidence
        },
        referenceData: state?.referenceData || initialState.referenceData
      },
      action
    );
  }

  if (action.type === RESET_PARTIAL_STORE_TO_INITIAL_STATE_FOR_COR_BRAZIL_IN_CD_BRAND) {
    return combinedReducers(
      {
        ...initialState,
        registrationDetails: {
          ...initialState.registrationDetails,
          countryOfResidence:
            state?.registrationDetails.countryOfResidence || initialState.registrationDetails.countryOfResidence
        }
      },
      action
    );
  }

  return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunkMiddleware]
});

export const persistor = persistStore(store);

export const removePersistedStoreData = () => {
  console.log('removePersistedStoreData called');

  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  });
};
