import { FC, useContext, useEffect, useRef, useState, ChangeEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { yupResolver } from '@hookform/resolvers/yup';

// Contexts
import { LanguageContext, TenantContext, LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPInput } from '../../../atoms/RPInput';
import { RPRadioButton } from '../../../atoms/RPRadioButton';
import { RPButton, RPButtonContainer } from '../../../atoms/RPButton';
import { RPText } from '../../../atoms/RPText';
import RPLoader from '../../../atoms/RPLoader';
import { RPConfirmationModal } from '../../../molecules/RPModals';
import { RPPasswordInput } from '../../../organisms/RPPasswordInput';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper/ControllerWrapper';
import RPInformationalBlurb from '../../../molecules/RPInformationalBlurb';
// Validation Schema or files in the same folder
import { ValidationSchema } from './ValidationSchema';
// Hooks
import {
  useCORValueFromUrl,
  useGetReferralRewardByLocale,
  useRegisterDeviceAndGenerateToken,
  useScrollToContentTop,
  useSetLanguageContextFromUrl,
  useStepTypeData,
  useGetRefererBranchIOInfoAndData
} from '../../../../core/hooks';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutSize } from '../../../../core/types/LayoutTypes';
import { OrganizationCodeTypes } from '../../../../core/types/OrganizationTypes';
import { CreateLoginType } from '../../../../core/types/RegistrationStepForm';
import { InformationalBlurbContent } from '../../../molecules/RPInformationalBlurb/RPInformationalBlurb';
// RTK Slice
import { removePersistedStoreData } from '../../../../redux/store';
import { getAuthorisationDetails } from '../../../../redux/modules/authorisationDetailsSlice';
import { updateCurrentStep } from '../../../../redux/modules/registrationStepsSlice';
import {
  addRegistrationsDetails,
  getRegistrationDetails,
  resetDuplicateAccount,
  resetLeadId
} from '../../../../redux/modules/registrationDetailsSlice';
import { getReferenceData } from '../../../../redux/modules/referenceDataSlice';
// API Wrappers
import checkDuplicateAccount from '../../../../redux/api/checkDuplicateAccount';
import addEnquiryLead, { PostDataType } from '../../../../redux/api/addEnquiryLead';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { getCFXForgotPasswordURL, getCFXLoginURL, getForgotPasswordURL, getLoginPageURL } from '../../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
import { getInformationalBlurbContentArray } from '../../../../core/utils/GetInformationalBlurbContentArray';
import { getIcon } from '../../../../core/utils/IconOrgData';
import { addUserContext } from '../../../../core/utils/Coralogix';
import { formatReferralRewards } from '../../../../core/utils/FormatReferralRewards';
// Constants
import {
  STEPS_ROUTE_MAPPING,
  LOGIN,
  RESET_PASSWORD,
  LOCALE_LANG_CODE_MAPPING,
  LANG_CODE_MAPPING,
  RAF_INFORMATIONAL_BLURB_CONTENT_ARRAY_DEFINITION,
  REMOVE_REFERRER_SECTION_CONFIG,
  ENABLE_PBU_CHECK_ORGANIZATIONS_LIST
} from '../../../../core/utils/Constants/Constants';
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

interface LayoutProps {
  layout: LayoutSize;
}

interface ReferredProps {
  isReferred: boolean;
}

interface FormWrapperProps extends LayoutProps, ReferredProps {
  removeReferrerSection: boolean;
}

const StyledHeading = styled(RPText)<LayoutProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  return {
    marginTop: '0',
    marginBottom: isDesktopLayout ? '40px' : isTabletLayout ? '30px' : '15px'
  };
});

const ReferredSubHeading = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    marginBottom: 15,
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const FormWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isReferred' && prop !== 'removeReferrerSection'
})<FormWrapperProps>(({ layout, isReferred, removeReferrerSection }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? 20 : 30,
    '> div': {
      marginBottom: '15px',

      // Last name
      ':nth-of-type(2)': {
        marginBottom: isMobileLayout ? '20px' : '30px'
      },

      // Password criteria
      ':nth-of-type(4)': {
        marginBottom: removeReferrerSection ? 0 : isMobileLayout ? '15px' : '30px'
      },

      //Referred radio buttons
      ':nth-of-type(5)': {
        marginBottom: isReferred ? '15px' : 0,
        '& .MuiFormGroup-root.MuiFormGroup-row': {
          'label:last-of-type': {
            marginRight: 0
          }
        }
      },

      // Referral code container
      ':nth-of-type(6)': {
        marginBottom: 0
      }
    }
  };
});

const LoaderContainer = styled(Box)<LayoutProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginTop: isMobileLayout ? '130px' : '0px'
  };
});

const CreateLogin: FC = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  let gaTagTriggerRef = useRef(false);

  const {
    email,
    firstName,
    lastName,
    password,
    referred,
    referralCodeOrName,
    isDuplicateAccount,
    isDuplicateAccountCFX,
    isDuplicateAccountPFX,
    isDuplicateAccountLoading,
    leadId,
    enquiryLeadLoading,
    loading
  } = useSelector(getRegistrationDetails);
  const { registerDeviceLoading, generateTokenLoading } = useSelector(getAuthorisationDetails);
  const { referralRewardsLoading, referralRewardsData, selectedLanguage } = useSelector(getReferenceData);

  const { nextStepType } = useStepTypeData('create-login');

  const { language, translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { tenant } = useContext(TenantContext);

  const PersonUserIcon = useMemo(() => getIcon(tenant, 'personUserIcon'), [tenant]);

  const [showDuplicateEmailModal, setShowDuplicateEmailModal] = useState<boolean>(false);
  const [sendToNextPage] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [loginPageURL, setLoginPageURL] = useState<string>('');
  const [forgotPasswordURL, setForgotPasswordURL] = useState<string>('');

  const headingText: string = translate('registration.createLogin.heading');
  const referredSubHeadingText: string = translate('registration.createLogin.referredSubHeading');
  const firstNameText: string = translate('registration.createLogin.firstName');
  const lastNameText: string = translate('registration.createLogin.lastName');
  const emailText: string = translate('registration.createLogin.email');
  const passwordText: string = translate('registration.createLogin.password');
  const referredText: string = translate('registration.createLogin.referred');
  const referralCodeText: string = translate('registration.createLogin.referralCode');
  const buttonText: string = translate('registration.createLogin.continueButtonText');
  const radioButtonYesLabelText: string = translate('registration.createLogin.radioButtonYesLabelText');
  const radioButtonNoLabelText: string = translate('registration.createLogin.radioButtonNoLabelText');

  const modalMessagePFX: string = translate('modal.duplicateAccount.headingPFX');
  const modalMessageCFX: string = translate('modal.duplicateAccount.headingCFX');
  const modalAdditionalMessage: string = translate('modal.duplicateAccount.description');
  const contactMessage: string = translate('modal.duplicateAccount.contactNumber');
  const modalPrimaryBtnLabel: string = translate('modal.duplicateAccount.primaryBtnLabel');
  const modalSecondaryBtnLabel: string = translate('modal.duplicateAccount.secondaryBtnLabel');
  const toolTipText: string = translate('registration.createLogin.passwordInvalid');
  const refererHeadingText: string = translate('registration.createLogin.refererInformationalBlurb.heading');

  const referredRadioOptions = [
    {
      label: radioButtonNoLabelText,
      value: 'no'
    },
    {
      label: radioButtonYesLabelText,
      value: 'yes'
    }
  ];

  const {
    createLogin: {
      firstName: { name: firstNameFieldName },
      lastName: { name: lastNameFieldName },
      email: { name: emailFieldName },
      password: { name: passwordFieldName },
      referred: { name: referredFieldName },
      referralCodeOrName: { name: referralCodeOrNameFieldName },
      submitButton: { name: submitButtonName, dataTestId: submitButtonDataTestId }
    }
  } = REGISTRATION_DEFINITIONS;

  const {
    control,
    watch,
    handleSubmit,
    resetField,
    formState: { isValid }
  } = useForm<CreateLoginType>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(ValidationSchema()),
    defaultValues: {
      firstName,
      lastName,
      email,
      password: '',
      referred,
      referralCodeOrName
    }
  });

  const {
    eventAction: { click, load },
    createYourLogin: {
      click: {
        referredYes,
        referredNo,
        continueButton,
        alreadyHaveAccountLoginButton,
        alreadyHaveAccountResetPassword,
        alreadyHaveAccountPhoneNumber,
        alreadyHaveCFXAccountLoginButton,
        alreadyHaveCFXAccountResetPassword,
        alreadyHaveCFXAccountPhoneNumber
      },
      load: { pageLoad, alreadyHaveAccount, alreadyHaveCFXAccount }
    }
  } = GoogleAnalyticsTagsMapping;

  useEffect(() => {
    if (gaTagTriggerRef.current) return;
    gaTagTriggerRef.current = true;
    TriggerGoogleAnalyticsTag(load, pageLoad);
  }, [gaTagTriggerRef]);

  const watchReferred = watch('referred');
  const isReferred: boolean = watchReferred === 'yes';

  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';
  const headingVariant: 'h2' | 'h3' = isDesktopLayout ? 'h2' : 'h3';

  useRegisterDeviceAndGenerateToken();

  // Custom hook to set language to context from URL
  useSetLanguageContextFromUrl('lang');

  // Custom hook to set COR details to store from URL
  useCORValueFromUrl('cor');

  const { shouldShowRefererInfo, refererName } = useGetRefererBranchIOInfoAndData();
  
  useGetReferralRewardByLocale(referralRewardsData?.legalEntity, shouldShowRefererInfo);

  const removeReferrerSection: boolean = REMOVE_REFERRER_SECTION_CONFIG.includes(tenant as OrganizationCodeTypes);

  useEffect(() => {
    resetField('referralCodeOrName');
  }, [isReferred]);

  useEffect(() => {
    const isCurrentTenantPropertyPay = ENABLE_PBU_CHECK_ORGANIZATIONS_LIST.includes(tenant as OrganizationCodeTypes);
    const hasDuplicateAccount = isCurrentTenantPropertyPay ? isDuplicateAccount : isDuplicateAccountPFX;

    if (hasDuplicateAccount) {
      const isPBUAccount = isCurrentTenantPropertyPay && isDuplicateAccountCFX;
      const currentModalMessage: string = isPBUAccount ? modalMessageCFX : modalMessagePFX;
      const currentRedirectURL: string = isPBUAccount ? getCFXLoginURL() : getLoginPageURL(tenant);
      const currentForgotPasswordURL: string = isPBUAccount ? getCFXForgotPasswordURL() : getForgotPasswordURL(tenant);

      setModalMessage(currentModalMessage);
      setLoginPageURL(currentRedirectURL);
      setForgotPasswordURL(currentForgotPasswordURL);
      setShowDuplicateEmailModal(hasDuplicateAccount);
      TriggerGoogleAnalyticsTag(load, isDuplicateAccountCFX ? alreadyHaveCFXAccount : alreadyHaveAccount);
    }

    const noDuplicateAccount: boolean = isDuplicateAccountLoading === 'succeeded' && !hasDuplicateAccount;
    const emptyLeadId: boolean = enquiryLeadLoading === 'idle' && leadId === '';
    const enquiryLeadSuccessful: boolean = enquiryLeadLoading === 'succeeded' && leadId !== '';

    if (noDuplicateAccount && emptyLeadId) {
      dispatch(resetLeadId());
      sendEnquiryLead();
      addUserContext(email);
    }

    if ((noDuplicateAccount || sendToNextPage) && enquiryLeadSuccessful) {
      dispatch(resetDuplicateAccount());
      navigate(STEPS_ROUTE_MAPPING[nextStepType]);
    }
  }, [isDuplicateAccountLoading, isDuplicateAccount, enquiryLeadLoading, leadId, sendToNextPage]);

  useEffect(() => {
    // Reset duplicate account on page load if isDuplicateAccount = true
    isDuplicateAccount && dispatch(resetDuplicateAccount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const onSubmit: SubmitHandler<CreateLoginType> = (data) => {
    TriggerGoogleAnalyticsTag(click, continueButton);
    console.log(data);
    dispatch(addRegistrationsDetails(data));
    dispatch(
      updateCurrentStep({
        stepType: nextStepType
      })
    );
    dispatch(checkDuplicateAccount(data));
    dispatch(resetLeadId());
  };

  const handleClose = () => {
    dispatch(resetDuplicateAccount());
    setShowDuplicateEmailModal(false);
  };

  const handleLoginOrResetPasswordClick = (url: string, action: string) => {
    if (isDuplicateAccountCFX) {
      TriggerGoogleAnalyticsTag(
        click,
        action === LOGIN ? alreadyHaveCFXAccountLoginButton : alreadyHaveCFXAccountResetPassword
      );
    } else {
      TriggerGoogleAnalyticsTag(
        click,
        action === LOGIN ? alreadyHaveAccountLoginButton : alreadyHaveAccountResetPassword
      );
    }
    removePersistedStoreData();
    setShowDuplicateEmailModal(false);
    window.open(url, '_self');
  };

  const sendEnquiryLead = () => {
    const data: PostDataType = {
      firstName,
      lastName,
      email,
      referralCodeOrName,
      locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
    };
    dispatch(addEnquiryLead(data));
  };

  const contactNumberClicked = () => {
    TriggerGoogleAnalyticsTag(click, isDuplicateAccountCFX ? alreadyHaveCFXAccountPhoneNumber : alreadyHaveAccountPhoneNumber);
  };

  const disableSubmit: boolean =
    !isValid ||
    (isDuplicateAccount && showDuplicateEmailModal) ||
    enquiryLeadLoading === 'loading' ||
    isDuplicateAccountLoading === 'loading';

  const isLoading: boolean =
    registerDeviceLoading === 'loading' ||
    generateTokenLoading === 'loading' ||
    loading === 'loading' ||
    referralRewardsLoading === 'loading';

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue: string = event?.target?.value;
    const currentIsReferred: boolean = selectedValue === 'yes';
    TriggerGoogleAnalyticsTag(click, currentIsReferred ? referredYes : referredNo);
  };

  const referralRewardsValues = formatReferralRewards(
    referralRewardsData,
    referralRewardsLoading,
    tenant!,
    selectedLanguage
  );

  const InformationalBlurbContentArray: Array<InformationalBlurbContent> = useMemo(
    () =>
      getInformationalBlurbContentArray(
        RAF_INFORMATIONAL_BLURB_CONTENT_ARRAY_DEFINITION,
        translate,
        false,
        referralRewardsValues
      ),
    [translate, language, referralRewardsValues]
  );

  return (
    <>
      {isLoading ? (
        <LoaderContainer layout={layout}>
          <RPLoader size="medium" type="secondary" />
        </LoaderContainer>
      ) : (
        <>
          <StyledHeading variant={headingVariant} type="bold" text={headingText} layout={layout} />
          <RPConfirmationModal
            isOpen={showDuplicateEmailModal}
            message={modalMessage}
            additionMessage={modalAdditionalMessage}
            contactMessage={contactMessage}
            primaryBtnLabel={modalPrimaryBtnLabel}
            secondaryBtnLabel={modalSecondaryBtnLabel}
            handleClose={() => {
              handleClose();
            }}
            handlePrimaryBtnClick={() => handleLoginOrResetPasswordClick(loginPageURL, LOGIN)}
            handleSecondaryBtnClick={() => handleLoginOrResetPasswordClick(forgotPasswordURL, RESET_PASSWORD)}
            modalVariant="duplicateAccount"
            handleContactNoLinkClick={contactNumberClicked}
            size={isMobileLayout ? 'small' : 'normal'}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormWrapper layout={layout} isReferred={isReferred} removeReferrerSection={removeReferrerSection}>
              <ControllerElementWrapper
                name={firstNameFieldName}
                control={control}
                defaultValue={firstName}
                placeholderLabel={firstNameText}
                component={RPInput}
                isCoralogixMasked={true}
              />

              <ControllerElementWrapper
                name={lastNameFieldName}
                control={control}
                defaultValue={lastName}
                placeholderLabel={lastNameText}
                component={RPInput}
                isCoralogixMasked={true}
              />

              <ControllerElementWrapper
                name={emailFieldName}
                control={control}
                defaultValue={email}
                placeholderLabel={emailText}
                component={RPInput}
                isCoralogixMasked={true}
              />

              <ControllerElementWrapper
                name={passwordFieldName}
                control={control}
                defaultValue={password}
                placeholderLabel={passwordText}
                toolTipText={toolTipText}
                component={RPPasswordInput}
                isCoralogixMasked={true}
              />

              {!removeReferrerSection && (
                <>
                  {shouldShowRefererInfo ? (
                    <RPInformationalBlurb
                      contentArray={InformationalBlurbContentArray}
                      headingContent={refererHeadingText}
                      headingData={refererName}
                      icon={<PersonUserIcon />}
                    />
                  ) : (
                    <ControllerElementWrapper
                      name={referredFieldName}
                      control={control}
                      defaultValue={referred}
                      component={RPRadioButton}
                      label={referredText}
                      radioOptions={referredRadioOptions}
                      handleChange={handleRadioChange}
                    />
                  )}
                </>
              )}

              <Collapse easing="300" in={isReferred}>
                {
                  <>
                    <ReferredSubHeading variant="body2" type="normal" text={referredSubHeadingText} />
                    <ControllerElementWrapper
                      name={referralCodeOrNameFieldName}
                      control={control}
                      defaultValue={referralCodeOrName}
                      placeholderLabel={referralCodeText}
                      component={RPInput}
                      isCoralogixMasked={true}
                    />
                  </>
                }
              </Collapse>
              <RPButtonContainer layout={layout}>
                <RPButton
                  size={buttonSize}
                  type="submit"
                  disabled={disableSubmit}
                  endIconType={
                    enquiryLeadLoading === 'loading' || isDuplicateAccountLoading === 'loading' ? 'loader' : ''
                  }
                  name={submitButtonName}
                  data-testid={submitButtonDataTestId}
                >
                  {buttonText}
                </RPButton>
              </RPButtonContainer>
            </FormWrapper>
          </form>
        </>
      )}
    </>
  );
};

export default CreateLogin;
