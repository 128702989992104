import { FC, useContext, useEffect, useMemo, useRef, useState, MouseEvent, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import RPLink from '../../../atoms/RPLink';
import { RHFRPRadioListButton } from '../../../atoms/RPRadioButton';
import RPLinearProgressBar from '../../../atoms/RPLinearProgressBar';
import { RPDocsRequiredDialog } from '../../../molecules/RPDialog';
import { RPConfirmationModal } from '../../../molecules/RPModals';
import UploadDesktop from './UploadDesktop';
import MobileUpload from './MobileUpload';
import SmsSentSuccess from './SmsSentSuccess';
import OnFido from './OnFido';
// Validation Schema or files in the same folder
import { ValidationSchema } from './ValidationSchema';
// Hooks
import { useScrollToContentTop } from '../../../../core/hooks';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
import { OrganizationCodeTypes } from '../../../../core/types/OrganizationTypes';
import { ConfirmingYouType } from '../../../../core/types/RegistrationStepForm';
import { DocType } from '../../../../core/types/PoaPoiDocsTypes';
import {
  RegistrationReturnProps,
  RegistrationReturnInactiveProps
} from '../../../../core/types/RegistrationReturnTypes';
// RTK Slice
import {
  getRegistrationDetails,
  setPoaView,
  setPoiView,
  setOnfidoView
} from '../../../../redux/modules/registrationDetailsSlice';
import { updateCurrentStep } from '../../../../redux/modules/registrationStepsSlice';
// API Wrappers
import getProofOfAddress from '../../../../redux/api/getProofOfAddress';
import getProofOfIdentity from '../../../../redux/api/getProofOfIdentity';
import saveDocuments from '../../../../redux/api/saveDocuments';
import mobileDownloadSMS from '../../../../redux/api/mobileDownloadSMS';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import {
  getBody2MediumStyles,
  getBody2NormalStyles
} from '../../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { getIcon } from '../../../../core/utils/IconOrgData';
import { extractDialingCodeValue } from '../../../../core/utils/DialingData';
import { getConfigProperty } from '../../../../core/EnvironmentConfig/EnvironmentConfig';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
import GetBasicDetails from '../../../../core/utils/GetBasicDetails';
import { checkIfPOIAndPOAIsApplicable } from '../../../../core/utils/CheckIfPOIAndPOAIsApplicable';
import { checkIfBrazilChangesApplicable } from '../../../../core/utils/CheckIfBrazilChangesApplicable';
// Constants
import {
  DOCUMENT_UPLOADING_PAGE_URL,
  DOCUMENT_UPLOADING_RETURN_PAGE_URL,
  SKIP_DOCS_PAGE_URL,
  SKIP_DOCS_RETURN_PAGE_URL,
  LOCALE_LANG_CODE_MAPPING,
  ORG_LANG_MAPPING,
  ENTER_KEY,
  LANG_CODE_MAPPING,
  DOCUMENT_UPLOADING_RETURN_INACTIVE_PAGE_URL,
  KYC_PASS_PAGE_URL,
  ORGANIZATION_CODE_MAPPING,
  INACTIVE_DASHBOARD_SPECIFIC_CHANGES_ORGANIZATIONS_LIST,
  DISABLE_MOBILE_RELATED_FEATURES,
  TENANTS
} from '../../../../core/utils/Constants/Constants';
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
import { triggerPoiPoaDocGATags } from '../../../../core/utils/GetPoaAndPoiDocsData';

interface RadioListContainerProps extends LayoutProps {}

interface DividerProps extends LayoutProps {}

interface StyledHeadingProps extends LayoutProps {}

interface StyledUnorderedListProps extends LayoutProps {}

interface StyledListItemTextProps extends LayoutProps {}

interface RegulatedFOTextProps extends LayoutProps {
  isDisplayInlineRequired: boolean;
}

interface RegulatedFOContainerProps extends LayoutProps {}

const StyledHeading = styled(RPText)<StyledHeadingProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '15px' : '20px',
    color: COLOUR_TEXT_TEXT
  };
});

const StyledDescription = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    marginBottom: '20px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const StyledUnorderedList = styled('ul')<StyledUnorderedListProps>(({ theme, layout }) => {
  const {
    colours: {
      backgrounds: { backgroundAltInverseWeak }
    }
  } = theme;

  const COLOUR_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgroundAltInverseWeak;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    backgroundColor: COLOUR_BACKGROUND_ALT_INVERSE_WEAK,
    padding: `15px 15px 15px ${isDesktopLayout ? '30px' : '15px'}`,
    margin: `0 0 ${isMobileLayout ? '15px' : '20px'} 0`,
    listStyleType: isDesktopLayout ? 'disc' : 'none',

    ...(!isDesktopLayout && {
      '> li': {
        ':nth-of-type(1)': {
          marginBottom: '10px'
        },
        '::marker': {
          height: '16px'
        }
      }
    })
  };
});

const StyledListItemText = styled(RPText)<StyledListItemTextProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    ...(isDesktopLayout && {
      display: 'inline'
    })
  };
});

const RegulatedFOContainer = styled('div')<RegulatedFOContainerProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';

  return {
    marginBottom: isDesktopLayout ? '40px' : isTabletLayout ? '30px' : '20px'
  };
});

const RegulatedFOText = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isDisplayInlineRequired'
})<RegulatedFOTextProps>(({ theme, layout, isDisplayInlineRequired }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    ...(isDesktopLayout
      ? {
          ...getBody2MediumStyles(theme)
        }
      : {
          ...getBody2NormalStyles(theme)
        }),
    color: COLOUR_TEXT_TEXT_WEAK,
    display: isDisplayInlineRequired ? 'inline' : 'block'
  };
});

interface DocsRequiredLinkProps {
  isDisplayInlineRequired: boolean;
}

const DocsRequiredLink = styled(RPLink, {
  shouldForwardProp: (prop) => prop !== 'isDisplayInlineRequired'
})<DocsRequiredLinkProps>(({ isDisplayInlineRequired }) => {
  console.log('isDisplayInlineRequired', isDisplayInlineRequired);
  return {
    '> p': {
      display: isDisplayInlineRequired ? 'inline' : 'block'
    }
  };
});

const UploadDocsHeading = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '15px'
  };
});

const RadioListContainer = styled('div')<RadioListContainerProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    display: 'flex',
    flexDirection: isDesktopLayout ? 'row' : 'column',
    gap: '15px',
    justifyContent: 'space-between',
    marginBottom: isDesktopLayout ? '40px' : '30px',

    '> div': {
      ...(isDesktopLayout && {
        width: '50%'
      }),
      marginBottom: 0
    }
  };
});

const UploadIconContainer = styled('span')(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    display: 'flex',

    '> svg': {
      width: '24px',
      height: '24px',

      ' > path': {
        fill: COLOUR_TEXT_TEXT_LINK
      }
    }
  };
});

const Divider = styled('hr')<DividerProps>(({ theme, layout }) => {
  const {
    colours: {
      borders: { borderInput }
    }
  } = theme;

  const COLOUR_BORDER_INPUT: HexColour = borderInput;
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';

  return {
    borderTop: `1px solid ${COLOUR_BORDER_INPUT}`,
    marginBottom: isDesktopLayout ? 40 : isTabletLayout ? 30 : 20,
    marginTop: 0
  };
});

const BottomText = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '10px'
  };
});

const UploadDocLaterLink = styled(RPLink)(() => {
  return {
    marginBottom: '15px',
    display: 'inline-block'
  };
});

interface KYCFailProps extends RegistrationReturnProps, RegistrationReturnInactiveProps {}
const KYCFail: FC<KYCFailProps> = ({
  isRegistrationReturn = false,
  isRegistrationReturnInactive = false
}: KYCFailProps) => {
  const { language, translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const dispatch: any = useDispatch();

  let dataFetchedRef = useRef(false);
  let componentLoadedRef = useRef(false);

  const { isUpdateDocLaterInactiveDashboardFlowEnabled } = GetBasicDetails();

  const radioListButtonDefaultValue: 'desktopUpload' | null = isRegistrationReturnInactive ? 'desktopUpload' : null;

  const shouldUpdateUploadDocLaterLink: boolean =
    isUpdateDocLaterInactiveDashboardFlowEnabled &&
    INACTIVE_DASHBOARD_SPECIFIC_CHANGES_ORGANIZATIONS_LIST.includes(
      tenant || (ORGANIZATION_CODE_MAPPING.currenciesdirect as OrganizationCodeTypes)
    );

  const {
    complianceStatus,
    complianceCode,
    crmAccountID,
    crmContactID,
    countryOfResidence,
    email,
    isPoaRequired: isPoaEnable,
    isPoiRequired: isPoiEnable,
    loading: mobileDownloadSMSLoading,
    organizationLegalEntity: legalEntity,
    isOnfidoRequired
  } = useSelector(getRegistrationDetails);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    watch,
    trigger,
    formState: { isValid, errors },
    getFieldState,
    getValues,
    reset,
    setError,
    clearErrors
  } = useForm<ConfirmingYouType>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      documentUpload: radioListButtonDefaultValue,
      isPoiRequired: isPoiEnable, //isPoiRequired,
      poi: '',
      isPoaRequired: isPoaEnable,
      poa: '',
      poiFiles: null,
      poaFiles: null,
      poiFileNames: [],
      selectedPoiDocSides: 0,
      selectedPoaDocSides: 0
    },
    resolver: yupResolver(ValidationSchema())
  });

  // TODO Fetch Registration Return specific GA tags
  const {
    eventAction: { load, click },
    kycFailedDocument: {
      click: {
        useMobileApp,
        useMobileAppReturn,
        uploadNow,
        uploadNowReturn,
        mobileUploadContinue,
        skipDocumentDoItLater,
        seeDocumentRequirementsLink,
        seeDocumentRequirementsLinkReturn,
        seeDocumentRequirementsLinkReturnInactive,
        uploadDocsLater,
        uploadDocsLaterReturn,
        skipDocumentUploadNowCTA
      },
      load: {
        poaLoaded,
        poaLoadedReturn,
        poaLoadedReturnInactive,
        poiLoaded,
        poiLoadedReturn,
        poiAndPoaLoaded,
        poiAndPoaLoadedReturn,
        poiAndPoaLoadedReturnInactive,
        skipDocumentPopUp
      }
    }
  } = GoogleAnalyticsTagsMapping;

  useEffect(() => {
    isPoiEnable && setValue('isPoiRequired', isPoiEnable);
    isPoaEnable && setValue('isPoaRequired', isPoaEnable);
  }, [isPoiEnable, isPoaEnable]);

  useEffect(() => {
    if (componentLoadedRef.current) return;

    componentLoadedRef.current = true;

    if (isRegistrationReturnInactive && complianceCode === '') {
      navigate('/generic-error-return-inactive');
    } else {
      setShowPageContent(true);
    }

    dispatch(
      updateCurrentStep({
        stepType: 'confirming-you'
      })
    );

    const requestData = {
      locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN],
      legalEntity
    };

    if (complianceStatus === 'INACTIVE') {
      switch (complianceCode) {
        case '918':
          dispatch(setPoaView());
          dispatch(getProofOfAddress(requestData));
          TriggerGoogleAnalyticsTag(
            load,
            isRegistrationReturn ? poaLoadedReturn : isRegistrationReturnInactive ? poaLoadedReturnInactive : poaLoaded
          );
          break;
        case '919':
          if (getConfigProperty('isOnfidoEnabled')) {
            dispatch(setOnfidoView());
          } else {
            dispatch(setPoiView());
            dispatch(getProofOfIdentity(requestData));
          }
          if (!isRegistrationReturnInactive) {
            TriggerGoogleAnalyticsTag(load, isRegistrationReturn ? poiLoadedReturn : poiLoaded);
          }
          break;
        case '908':
        case '909':
        case '910':
          if (getConfigProperty('isOnfidoEnabled') && checkIfPOIAndPOAIsApplicable(countryOfResidence, legalEntity, complianceCode)) {
            dispatch(setOnfidoView());
          } else {
            dispatch(setPoaView());
            dispatch(getProofOfAddress(requestData));
            dispatch(setPoiView());
            dispatch(getProofOfIdentity(requestData));
          }
          // TODO: Check changes for GA
          TriggerGoogleAnalyticsTag(
            load,
            isRegistrationReturn
              ? poiAndPoaLoadedReturn
              : isRegistrationReturnInactive
              ? poiAndPoaLoadedReturnInactive
              : poiAndPoaLoaded
          );
          break;
        default:
          break;
      }
    }
  }, [complianceStatus, complianceCode, dataFetchedRef]);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const { documentUpload, poi: poiValue, poa: poaValue } = watch();

  const { isTouched: isDocUploadTouched } = getFieldState('documentUpload');

  const UploadDesktopIcon = useMemo(() => getIcon(tenant, 'fileFolderAdd'), [tenant]);
  const UploadMobileIcon = useMemo(() => getIcon(tenant, 'technologyMobile'), [tenant]);

  const [isDocRequiredDialogOpen, setIsDocRequiredDialogOpen] = useState(false);
  const [isSkipDocsModalOpen, setIsSkipDocsModalOpen] = useState(false);
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [showPageContent, setShowPageContent] = useState<boolean>(false);

  const isDesktopLayout: boolean = layout === 'desktop';
  const textType: 'medium' | 'normal' = isDesktopLayout ? 'medium' : 'normal';
  const size: 'small' | 'normal' = isMobileLayoutApplicable(layout) ? 'small' : 'normal';
  const uploadDocsHeadingVariant: 'body1' | 'body2' = isDesktopLayout ? 'body1' : 'body2';

  const showSMSSentView: boolean = isSmsSent && mobileDownloadSMSLoading === 'succeeded';

  const { shouldApplyBrazilChanges } = checkIfBrazilChangesApplicable(tenant, countryOfResidence);

  const disableUseMobileAppFlow: boolean =
    DISABLE_MOBILE_RELATED_FEATURES.includes(tenant as OrganizationCodeTypes) || shouldApplyBrazilChanges;
  const showDocumentUploadSection: boolean = documentUpload === 'desktopUpload' || disableUseMobileAppFlow;

  const {
    confirmingYou: {
      kycFail: {
        documentUpload: { name: documentUploadFieldName },
        documentRequirements: { dataTestId: documentRequirementsLinkDataTestId },
        uploadDocumentsLater: { dataTestId: uploadDocumentsLaterLinkDataTestId }
      }
    }
  } = REGISTRATION_DEFINITIONS;

  const heading: string = translate('registration.confirmingItsYou.heading');
  const description: string = translate('registration.confirmingItsYou.description');
  const poiBullet: string = translate('registration.confirmingItsYou.poiBullet');
  const poiBulletEg: string = translate('registration.confirmingItsYou.poiBulletEg');
  const poaBullet: string = translate('registration.confirmingItsYou.poaBullet');
  const poaBulletEg: string = translate('registration.confirmingItsYou.poaBulletEg');
  const regulatedFOText: string = translate('registration.confirmingItsYou.regulatedFOText');
  const docsRequiredText: string = translate('registration.confirmingItsYou.docsRequiredText');
  const uploadDocsHeading: string = translate('registration.confirmingItsYou.uploadDocs.heading');
  const uploadDesktopLabel: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.label');
  const uploadMobileLabel: string = translate('registration.confirmingItsYou.uploadDocs.uploadMobile.label');
  const dontHaveTimeText: string = translate('registration.confirmingItsYou.dontHaveTimeText');
  const comeBackLaterText: string = translate('registration.confirmingItsYou.comeBackLaterText');
  const uploadLaterText: string = translate('registration.confirmingItsYou.uploadLaterText');
  const skipDocsMessage: string = translate('modal.skipDocs.heading');
  const skipDocsAdditionalMessage: string = translate('modal.skipDocs.description');
  const skipDocsPrimaryBtnLabel: string = translate('modal.skipDocs.primaryBtnLabel');
  const skipDocsSecondaryBtnLabel: string = translate('modal.skipDocs.secondaryBtnLabel');
  const inactiveRegistrationHeading: string = translate('registration.confirmingItsYou.inactiveRegistration.heading');
  const inactiveRegistrationSubHeading: string = translate(
    'registration.confirmingItsYou.inactiveRegistration.subHeading'
  );
  const inactiveRegistrationDocsRequiredText: string = translate(
    'registration.confirmingItsYou.inactiveRegistration.docsRequiredText'
  );

  const handleRadioChange = (value: string) => {
    if (value === 'mobileUpload') {
      TriggerGoogleAnalyticsTag(click, isRegistrationReturn ? useMobileAppReturn : useMobileApp);

      resetField('poi');
      setValue('poi', '');
    } else {
      TriggerGoogleAnalyticsTag(click, isRegistrationReturn ? uploadNowReturn : uploadNow);
    }
    // Reset the file upload when radio value changes for mobile upload
    reset((formValues) => ({
      ...formValues,
      poi: '',
      poa: '',
      poiFiles: null,
      poaFiles: null,
      poiFileNames: [],
      poaFileNames: [],
      selectedPoiDocSides: 0,
      selectedPoaDocSides: 0
    }));
  };

  const onSubmit: SubmitHandler<ConfirmingYouType> = (data) => {
    console.log('FileData', JSON.stringify(data));
    if (data?.poi.length) {
      // This function will find the poi doc key and trigger GA tag
      triggerPoiPoaDocGATags(DocType.poi, data?.poi);
    }
    if (data?.poa.length) {
      // This function will find the poa doc key and trigger GA tag
      triggerPoiPoaDocGATags(DocType.poa, data?.poa);
    }
    const documentPayload = {
      ...data,
      crmAccountID,
      crmContactID,
      email
    };
    dispatch(saveDocuments(documentPayload));
    const navigateToURL: string = isRegistrationReturn
      ? DOCUMENT_UPLOADING_RETURN_PAGE_URL
      : isRegistrationReturnInactive
      ? DOCUMENT_UPLOADING_RETURN_INACTIVE_PAGE_URL
      : DOCUMENT_UPLOADING_PAGE_URL;
    navigate(navigateToURL);
  };

  const sendSmsSubmitHandler = (countryCode: string, mobileNumber: string) => {
    TriggerGoogleAnalyticsTag(click, mobileUploadContinue);

    // TODO: Dynamically handle based on tenant
    const locale: string = LOCALE_LANG_CODE_MAPPING[language || ORG_LANG_MAPPING?.tenant[0]];

    dispatch(
      mobileDownloadSMS({
        countryCode: extractDialingCodeValue(countryCode),
        mobileNumber,
        legalEntity,
        locale
      })
    );
    setIsSmsSent(true);
  };

  const navigateToDocsSkipped = () => {
    // TODO: Add API call to send email
    const navigateToURL: string = isRegistrationReturn ? SKIP_DOCS_RETURN_PAGE_URL : SKIP_DOCS_PAGE_URL;
    navigate(navigateToURL);
    TriggerGoogleAnalyticsTag(click, skipDocumentDoItLater);
  };

  const navigateToKycPassPage = () => {
    navigate(KYC_PASS_PAGE_URL);
  };

  const uploadDocLaterLinkHandler = () => {
    if (shouldUpdateUploadDocLaterLink) {
      navigateToKycPassPage();
    } else {
      navigateToDocsSkipped();
    }
  };

  return (
    <>
      {showPageContent && (
        <>
          {isOnfidoRequired ? (
            <OnFido
              isRegistrationReturn={isRegistrationReturn}
              isRegistrationReturnInactive={isRegistrationReturnInactive}
            />
          ) : (
            <>
              {!showSMSSentView ? (
                <>
                  <StyledHeading
                    variant={isDesktopLayout ? 'h2' : 'h3'}
                    type="bold"
                    text={isRegistrationReturnInactive ? inactiveRegistrationHeading : heading}
                    layout={layout}
                  />
                  {!isRegistrationReturnInactive && isMobileLayoutApplicable(layout) && (
                    <RPLinearProgressBar value={80} />
                  )}
                  {!isRegistrationReturnInactive && (
                    <StyledDescription variant="body2" type={textType} text={description} />
                  )}
                  {!isRegistrationReturnInactive && (
                    <StyledUnorderedList layout={layout}>
                      {isPoiEnable && (
                        <li>
                          <StyledListItemText variant="body2" type="medium" text={poiBullet} layout={layout} />
                          <StyledListItemText variant="body2" type={textType} text={poiBulletEg} layout={layout} />
                        </li>
                      )}
                      {isPoaEnable && (
                        <li>
                          <StyledListItemText variant="body2" type="medium" text={poaBullet} layout={layout} />
                          <StyledListItemText variant="body2" type={textType} text={poaBulletEg} layout={layout} />
                        </li>
                      )}
                    </StyledUnorderedList>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RegulatedFOContainer layout={layout}>
                      <RegulatedFOText layout={layout} isDisplayInlineRequired={!isRegistrationReturnInactive}>
                        {isRegistrationReturnInactive ? inactiveRegistrationSubHeading : regulatedFOText}
                      </RegulatedFOText>
                      {isRegistrationReturnInactive ? '' : '\u00A0'}
                      <DocsRequiredLink
                        type="medium"
                        variant="body2"
                        text={isRegistrationReturnInactive ? inactiveRegistrationDocsRequiredText : docsRequiredText}
                        underline="hover"
                        href=""
                        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                          e.preventDefault();
                          setIsDocRequiredDialogOpen(true);
                          TriggerGoogleAnalyticsTag(
                            click,
                            isRegistrationReturn
                              ? seeDocumentRequirementsLinkReturn
                              : isRegistrationReturnInactive
                              ? seeDocumentRequirementsLinkReturnInactive
                              : seeDocumentRequirementsLink
                          );
                        }}
                        onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
                          if (e.key === ENTER_KEY) {
                            e.preventDefault();
                            setIsDocRequiredDialogOpen(true);
                          }
                        }}
                        data-testid={documentRequirementsLinkDataTestId}
                        isDisplayInlineRequired={!isRegistrationReturnInactive}
                      />
                      <RPDocsRequiredDialog
                        isOpen={isDocRequiredDialogOpen}
                        handleClose={() => setIsDocRequiredDialogOpen(false)}
                        size={size}
                        listSize={tenant === TENANTS.TORFX_OZ ? 6 : 5}
                      />
                    </RegulatedFOContainer>
                    {!isRegistrationReturnInactive && !disableUseMobileAppFlow && (
                      <>
                        <UploadDocsHeading variant={uploadDocsHeadingVariant} type="medium" text={uploadDocsHeading} />
                        <RadioListContainer layout={layout}>
                          <RHFRPRadioListButton
                            defaultValue={null}
                            control={control}
                            label={uploadDesktopLabel}
                            name={documentUploadFieldName}
                            value="desktopUpload"
                            isChecked={documentUpload === 'desktopUpload'}
                            handleChange={handleRadioChange}
                            icon={
                              <UploadIconContainer>
                                <UploadDesktopIcon />
                              </UploadIconContainer>
                            }
                            variantType="oneLineSmall"
                            hasError={isDocUploadTouched && !!errors.documentUpload?.message}
                          />

                          <RHFRPRadioListButton
                            defaultValue={null}
                            control={control}
                            label={uploadMobileLabel}
                            name={documentUploadFieldName}
                            value="mobileUpload"
                            isChecked={documentUpload === 'mobileUpload'}
                            handleChange={handleRadioChange}
                            icon={
                              <UploadIconContainer>
                                <UploadMobileIcon />
                              </UploadIconContainer>
                            }
                            variantType="oneLineSmall"
                            hasError={isDocUploadTouched && !!errors.documentUpload?.message}
                          />
                        </RadioListContainer>
                      </>
                    )}

                    <Collapse easing="300" in={showDocumentUploadSection}>
                      {showDocumentUploadSection && (
                        <UploadDesktop
                          setValue={setValue}
                          control={control}
                          trigger={trigger}
                          getValues={getValues}
                          resetField={resetField}
                          setError={setError}
                          clearErrors={clearErrors}
                          reset={reset}
                          errors={errors}
                          poiValue={poiValue}
                          poaValue={poaValue}
                          isValid={isValid}
                          isPoiRequired={isPoiEnable}
                          isPoaRequired={isPoaEnable}
                          isRegistrationReturnInactive={isRegistrationReturnInactive}
                        />
                      )}
                    </Collapse>

                    <Collapse easing="300" in={documentUpload === 'mobileUpload'}>
                      {documentUpload === 'mobileUpload' && <MobileUpload />}
                    </Collapse>
                  </form>

                  {!isRegistrationReturnInactive && (
                    <Collapse easing="300" in={documentUpload !== 'mobileUpload'}>
                      {documentUpload !== 'mobileUpload' && (
                        <>
                          <Divider layout={layout} />

                          <BottomText
                            variant={isDesktopLayout ? 'body1' : 'body2'}
                            type="medium"
                            text={dontHaveTimeText}
                          />
                          <BottomText variant="body2" type={textType} text={comeBackLaterText} />

                          <UploadDocLaterLink
                            variant="body2"
                            type="normal"
                            text={uploadLaterText}
                            underline="always"
                            href=""
                            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                              e.preventDefault();
                              setIsSkipDocsModalOpen(true);
                              TriggerGoogleAnalyticsTag(load, skipDocumentPopUp);
                              TriggerGoogleAnalyticsTag(
                                click,
                                isRegistrationReturn ? uploadDocsLaterReturn : uploadDocsLater
                              );
                            }}
                            onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
                              if (e.key === ENTER_KEY) {
                                e.preventDefault();
                                setIsSkipDocsModalOpen(true);
                              }
                            }}
                            data-testid={uploadDocumentsLaterLinkDataTestId}
                          />
                        </>
                      )}
                    </Collapse>
                  )}

                  {/*Skip docs modal */}
                  <RPConfirmationModal
                    isOpen={isSkipDocsModalOpen}
                    handleClose={() => setIsSkipDocsModalOpen(false)}
                    message={skipDocsMessage}
                    additionMessage={skipDocsAdditionalMessage}
                    primaryBtnLabel={skipDocsPrimaryBtnLabel}
                    handlePrimaryBtnClick={() => {
                      setIsSkipDocsModalOpen(false);
                      TriggerGoogleAnalyticsTag(click, skipDocumentUploadNowCTA);
                    }}
                    secondaryBtnLabel={skipDocsSecondaryBtnLabel}
                    handleSecondaryBtnClick={uploadDocLaterLinkHandler}
                    size={size}
                  />
                </>
              ) : (
                <SmsSentSuccess clickBackHandler={setIsSmsSent} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default KYCFail;
