import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// RTK Slice
import { getReferenceData } from '../../redux/modules/referenceDataSlice';
import { getAuthorisationDetails } from '../../redux/modules/authorisationDetailsSlice';
// API Wrappers
import getReferralRewards from '../../redux/api/getReferralRewards';
// Types
import { AppDispatch } from '../../redux/store';
// Utils
import GetBasicDetails from '../utils/GetBasicDetails';
// Constants
import { LOCALE_LANG_CODE_MAPPING } from '../utils/Constants/Constants';

const useGetReferralRewardByLocale = (legalEntity: string, shouldShowRefererInfo: boolean = false) => {
  const dispatch: AppDispatch = useDispatch();

  const { selectedLanguage } = useSelector(getReferenceData);
  const { generateTokenLoading } = useSelector(getAuthorisationDetails);
  
  useEffect(() => {
    const { localeId } = GetBasicDetails(LOCALE_LANG_CODE_MAPPING[selectedLanguage]);
    if (legalEntity && shouldShowRefererInfo && generateTokenLoading === 'succeeded') {
      const requestData = {
        legalEntity: legalEntity,
        localeId: `${localeId}`
      };

      dispatch(getReferralRewards(requestData));
    }
  }, [selectedLanguage, generateTokenLoading]);
};

export default useGetReferralRewardByLocale