import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

export type CreateBiometricCheckData = {
  checkType: string;
  customerID: string;
  sdkToken: string;
  organizationLegalEntity?: string;
  requestedVariant?: string;
};

const createBiometricCheck = createAsyncThunk(
  'v3.6/applicants/checks',
  async (createBiometricCheckData: CreateBiometricCheckData, thunkAPI) => {
    const { checkType, customerID, sdkToken, organizationLegalEntity, requestedVariant } = createBiometricCheckData;

    const payload = {
      check_type: checkType,
      customer_id: customerID,
      sdkToken: sdkToken,
      ...(organizationLegalEntity &&
        { legalEntity: organizationLegalEntity }
      ),
      ...(requestedVariant && {
        requestedVariant
      })
    };

    try {
      const response = await axiosWrapper('createBiometricCheck', 'POST', thunkAPI, payload);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default createBiometricCheck;
