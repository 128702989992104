import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import GetBasicDetails from '../../core/utils/GetBasicDetails';

export type documentData = {
  poiFiles: File[] | null;
  poaFiles: File[] | null;
  crmAccountID: string;
  crmContactID: string;
  email: string;
};

export type TaxStatementDocumentData = {
  taxStatementDocument: File  | null;
  leadId: string;
  email: string;
}

const { orgCode, source } = GetBasicDetails();

const saveDocuments = createAsyncThunk(
  'registration-service/saveDocuments',
  async (data: documentData | TaxStatementDocumentData, thunkAPI) => {
    const {
      poiFiles,
      poaFiles,
      crmAccountID,
      crmContactID,
      email
    } = data as documentData;

    const { taxStatementDocument, leadId, email: currentEmail } = data as TaxStatementDocumentData;

    const dataToInsert: ObjectMapping = {
      ...(crmAccountID && { crmAccountID }),
      ...(crmContactID && { crmContactID }),
      ...(source && { source }),
      ...(orgCode && { orgCode }),
      ...(email && { email }),
      ...(currentEmail && { email: currentEmail }),
      ...(leadId && { leadId })
    };

    const formData = new FormData();

    let saveDocumentsAPIType: 'lead' | 'account' = 'account';

    if (poiFiles && poiFiles.length > 0) {
      poiFiles.forEach((fileObject: string | Blob) => {
        formData.append('files', fileObject);
      })
    }
    if (poaFiles && poaFiles.length > 0) {
      poaFiles.forEach((fileObject: string | Blob) => {
        formData.append('files', fileObject);
      })
    }
    if (taxStatementDocument) {
      saveDocumentsAPIType = 'lead';
      formData.append('files', taxStatementDocument);
    }

    const axiosAPIType: string = saveDocumentsAPIType === 'lead' ? 'saveDocumentsLeadV1' : 'saveDocumentsAccountV1';

    try {
      const response = await axiosWrapper(axiosAPIType, 'POST', thunkAPI, formData, dataToInsert);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default saveDocuments;
