import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useForm, SubmitHandler } from 'react-hook-form';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import RPInput from '../../../atoms/RPInput';
import RPButton, { RPButtonContainer } from '../../../atoms/RPButton';
import RPLoader from '../../../atoms/RPLoader';
import { RPSelectNew } from '../../../atoms/RPSelect';
import RPLinearProgressBar from '../../../atoms/RPLinearProgressBar';
import { RHFRPAddressLookup } from '../../../molecules/RPAddressLookup';
import { RHFRPDoB } from '../../../molecules/RPDoB';
import { RPConfirmationModal, NoServiceModal, PartialServiceModal } from '../../../molecules/RPModals';
import AgreeTnCPrivacyPolicy from '../../../molecules/AgreeTnCPrivacyPolicy';
import WhyDoWeNeedThisSection from '../../../molecules/WhyDoWeNeedThisSection';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper/ControllerWrapper';
import USADetails from './USADetails';
import ESPDetails from './ESPDetails';
import LocationStatusMessage from './LocationStatusMessage';
import EmploymentFields from './EmploymentFields';
import EmploymentOccupation from './EmploymentOccupation';
import BRADetails from './BRADetails';
// Validation Schema or files in the same folder
import { ValidationSchema } from './ValidationSchema';
// Hooks
import { useScrollToContentTop, useStepTypeData } from '../../../../core/hooks';
// Types
import {
  BRARegDetailsType,
  EnterDetailsType,
  ESPRegDetailsType,
  USARegDetailsType
} from '../../../../core/types/RegistrationStepForm';
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
import { OptionType } from '../../../../core/types/SelectTypes';
import { AddressType } from '../../../../core/types/addressLookupTypes';
import {
  CountryCodesWithStateDropdown,
  StateInfo,
  StateLicenseStatusType
} from '../../../../core/types/CountryStateTypes';
import { EmploymentStatusType } from '../../../../core/types/EmploymentFieldTypes';
// RTK Slice
import {
  getRegistrationDetails,
  addEnterDetails,
  resetAddressData,
  resetDuplicateAccountAdvance,
  startRegistrationProcess,
  resetUpdateLeadLoading,
  resetVerifyETVLoading,
  updateZarModalOpenStatus,
  updateETVValue
} from '../../../../redux/modules/registrationDetailsSlice';
import {
  addPreferencesStep,
  addTaxStatementStep,
  getRegistrationSteps,
  removePreferencesStep,
  removeTaxStatementStep,
  updateCurrentStep
} from '../../../../redux/modules/registrationStepsSlice';
import { getReferenceData } from '../../../../redux/modules/referenceDataSlice';
// API Wrappers
import getCountryData from '../../../../redux/api/getCountryData';
import getAdditionalInfoFields from '../../../../redux/api/getAdditionalInfoFields';
import checkDuplicateAccountAdvanced from '../../../../redux/api/checkDuplicateAccountAdvanced';
import getCountryStateData from '../../../../redux/api/getCountryStateData';
import updateLead from '../../../../redux/api/updateLead';
import getEmploymentStatusData from '../../../../redux/api/getEmploymentStatusData';
import getEmploymentIndustryData from '../../../../redux/api/getEmploymentIndustryData';
import getOccupationData from '../../../../redux/api/getOccupationData';
import getCurrencyData from '../../../../redux/api/getCurrencyData';
import getEtaData from '../../../../redux/api/getEtaData';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { getCountryName, getStateName } from '../../../../core/utils/CountryData';
import { extractDialingCodeValue } from '../../../../core/utils/DialingData';
import { getTnCPrivacyPolicyLinks } from '../../../../core/utils/GetTnCPrivacyPolicyLinks';
import { getForgotPasswordURL, getLoginPageURL } from '../../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
import { checkIfCFSBIsEnabled } from '../../../../core/utils/CheckIfCFSBIsEnabled';
import { getCurrencyPairAndEtvAmount } from '../../../../core/utils/GetCurrencyPairAndEtvAmount';
import { getCurrentTenant } from '../../../../core/utils/GetCurrentTenant';
import { getCPFNumberFormatted } from '../../../../core/utils/GetCPFNumberFormatted';
import { checkIfBrazilChangesApplicable } from '../../../../core/utils/CheckIfBrazilChangesApplicable';
// Constants
import {
  STEPS_ROUTE_MAPPING,
  USA,
  ESP,
  LICENSE_STATUS_COUNTRIES,
  COUNTRY_ID_WITH_STATE_DROPDOWN,
  LOCALE_LANG_CODE_MAPPING,
  LANG_CODE_MAPPING,
  CD_SA_URL,
  ZAF,
  SHOW_ZAR_MODAL_ORGANIZATIONS_LIST,
  ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_DETAILS,
  BRA,
  EXTRA_FIELD_COUNTRIES_BRAND_SPECIFIC_MAPPING
} from '../../../../core/utils/Constants/Constants';
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
// Feature Flag
import { featureFlags } from '../../../../core/FeatureFlags/FeatureFlags';

interface StyledHeadingProps extends LayoutProps {}

interface StyledDescriptionProps extends LayoutProps {}

interface FormWrapperProps extends LayoutProps {
  shouldShowEmploymentFields: boolean;
  showEmploymentIndustryDropdown: boolean;
}

const StyledHeading = styled(RPText)<StyledHeadingProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '15px' : '20px',
    color: COLOUR_TEXT_TEXT
  };
});

const StyledDescription = styled(RPText)<StyledDescriptionProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    marginBottom: isDesktopLayout ? '40px' : '30px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const FormWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shouldShowEmploymentFields' && prop !== 'showEmploymentIndustryDropdown'
})<FormWrapperProps>(({ layout, shouldShowEmploymentFields, showEmploymentIndustryDropdown }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const customMarginBottom: 15 | 20 = isMobileLayout ? 15 : 20;

  return {
    '& > div': {
      marginBottom: 15,

      // Address
      ':nth-of-type(2)': {
        marginBottom: customMarginBottom
      },

      // Date of birth
      ':nth-of-type(3)': {
        marginBottom: customMarginBottom
      },

      // USA fields wrapper
      ':nth-of-type(4)': {
        marginBottom: 0
      },

      // ESP fields wrapper
      ':nth-of-type(5)': {
        marginBottom: 0
      },

      ...(shouldShowEmploymentFields
        ? {
            // Employment status
            ':nth-of-type(6)': {
              marginBottom: 15
            },

            // Employment industry
            ':nth-of-type(7)': {
              marginBottom: showEmploymentIndustryDropdown ? 15 : 0
            },

            // why do we need this description
            ':nth-of-type(8)': {
              marginBottom: 0
            }
          }
        : {
            // Default occupation
            ':nth-of-type(6)': {
              marginBottom: isDesktopLayout ? 15 : 10
            },

            // why do we need this description
            ':nth-of-type(7)': {
              marginBottom: 0
            }
          })
    }
  };
});

const StyledFieldHeading = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    marginBottom: '10px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const AgreeTnCContainer = styled('div')(() => {
  return {
    marginTop: '30px'
  };
});

const {
  enterDetails: { showEmploymentFields, showOccupationDropdown }
} = featureFlags;

const EnterDetails: FC = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { translations, language } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { tenant } = useContext(TenantContext);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const { termsAndConditions: tncLink, privacyPolicy: privacyPolicyLink } = getTnCPrivacyPolicyLinks(tenant, language);

  const {
    countryData,
    countryLoading,
    countryStateLoading,
    countryStateData,
    languageForCountryData,
    employmentStatusLoading,
    employmentStatusData,
    employmentIndustryLoading,
    ipBasedCountryCode,
    locationLookupLoading,
    occupationDataLoading,
    occupationData,
    currenciesLoading,
    currenciesOptionData,
    etvLoading,
    etvOptionData
  } = useSelector(getReferenceData);

  const {
    countryOfResidence,
    stateLicenseStatus,
    countryOfResidenceName,
    address,
    dob,
    occupation,
    isETVExist,
    agreeTC,
    isDuplicateActAdvanceLoading,
    isDuplicateActAdvance,
    firstName,
    lastName,
    leadId,
    mobileNumber,
    countryCode,
    usa,
    esp,
    isValidOTP,
    updateLeadStatus,
    updateLeadLoading,
    queryCOR: queryCORValue,
    verifyETVLoading,
    employmentStatus,
    employmentIndustry,
    hasZARModalBeenOpened
  } = useSelector(getRegistrationDetails);

  const { steps } = useSelector(getRegistrationSteps);

  const { previousStepType, nextStepType, currentStepProgress } = useStepTypeData('enter-details');

  const [selectedCountryForSearch, setSelectedCountry] = useState<string>(countryOfResidence);
  const [selectedCountryOfResidenceName, setSelectedCountryOfResidenceName] = useState<string>(countryOfResidenceName);
  const [isPartialLocationWarningOpen, setIsPartialLocationWarningOpen] = useState(false);
  const [isNoLocationWarningOpen, setIsNoLocationWarningOpen] = useState(false);
  const [showDuplicateEmailModal, setShowDuplicateEmailModal] = useState<boolean>(false);
  const [stateDropdownOptions, setStateDropdownOptions] = useState<OptionType[]>([]);
  const [showEmploymentIndustryDropdown, setShowEmploymentIndustryDropdown] = useState(!!employmentIndustry);
  const [isZARModalOpen, setIsZARModalOpen] = useState<boolean>(false);
  const [showTnCCheckBox, setShowTnCCheckBox] = useState<boolean>(false);

  let dataFetchedRef = useRef(false);
  let etvDataFetchedRef = useRef(false);
  let employmentDataFetchedRef = useRef(false);
  let occupationDataFetchedRef = useRef(false);
  let addTaxStatementRef = useRef(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    resetField,
    trigger,
    formState: { isValid, errors }
  } = useForm<EnterDetailsType>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(ValidationSchema()),
    defaultValues: {
      countryOfResidence,
      agreeTC: showTnCCheckBox ? !!agreeTC : null,
      stateLicenseStatus: stateLicenseStatus
    }
  });

  const {
    countryOfResidence: CORValue,
    address: addressValue,
    dob: dobValue,
    stateLicenseStatus: stateLicenseStatusValue,
    occupation: occupationValue,
    agreeTC: agreeTCValue,
    bra: braValue
  } = watch();

  const isCFSBEnabled: boolean = checkIfCFSBIsEnabled();

  const buttonSize: 'medium' | 'large' = isMobileLayoutApplicable(layout) ? 'medium' : 'large';

  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const trimmedCORValue: string | undefined = CORValue?.trim().toLowerCase();
  const corIsUSA: boolean = trimmedCORValue === USA;
  const corIsSpain: boolean = trimmedCORValue === ESP;
  const corIsBrazil: boolean = trimmedCORValue === BRA;
  const isValidLicenseStatusCountry: boolean = LICENSE_STATUS_COUNTRIES.includes(trimmedCORValue);

  const isContinueBtnDisabled: boolean =
    !isValid || countryStateLoading === 'loading' || countryStateLoading === 'failed';

  const dobError: string | undefined = dobValue?.day && dobValue?.month && dobValue?.year ? errors.dob?.message : '';

  const showLoader: boolean = isDuplicateActAdvanceLoading === 'loading' || updateLeadLoading === 'loading';

  const isPartialServiceLocation: boolean = stateLicenseStatusValue === 1;
  const isNoServiceLocation: boolean = stateLicenseStatusValue === 0;

  const loginPageURL: string = getLoginPageURL(tenant);
  const forgotPasswordURL: string = getForgotPasswordURL(tenant);

  const localeValue: string = LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN];

  const setPreferencesStepIndex: number = steps.findIndex(
    (registrationStep) => registrationStep.type === 'set-preferences'
  );

  const setTaxStatementIndex: number = steps.findIndex((registrationStep) => registrationStep.type === 'tax-statement');

  const { shouldApplyBrazilChanges } = checkIfBrazilChangesApplicable(tenant, CORValue);

  const {
    landingPage: { useReactLandingPage }
  } = featureFlags;

  const { currentTenant, isCurrentTenantPropertyPay } = getCurrentTenant(tenant);

  const extraFieldCountries: string[] = EXTRA_FIELD_COUNTRIES_BRAND_SPECIFIC_MAPPING[currentTenant];

  const {
    enterDetails: {
      countryOfResidence: { name: countryOfResidenceFieldName },
      dob: {
        day: { name: dobDayFieldName },
        month: { name: dobMonthFieldName },
        year: { name: dobYearFieldName }
      },
      occupation: { name: occupationFieldName },
      continueButton: { name: continueButtonName, dataTestId: continueButtonDataTestId },
      backButton: { name: backButtonName, dataTestId: backButtonDataTestId }
    }
  } = REGISTRATION_DEFINITIONS;

  const heading: string = translate('registration.enterDetails.heading');
  const description: string = translate('registration.enterDetails.description');
  const countryOfResidenceLabel: string = translate('registration.enterDetails.countryOfResidence.label');
  const addressSearchHeading: string = translate('registration.enterDetails.addressSearch.heading');
  const dobHeading: string = translate('registration.enterDetails.dob.label');
  const occupationHeading: string = translate('registration.enterDetails.occupation.heading');
  const occupationLabel: string = translate('registration.enterDetails.occupation.label');

  const continueButtonLabel: string = translate('registration.enterDetails.continueButtonText');
  const backButtonLabel: string = translate('registration.enterDetails.backButtonText');

  const modalMessage: string = translate('modal.duplicateAccount.headingPFX');
  const modalAdditionalMessage: string = translate('modal.duplicateAccount.description');
  const contactMessage: string = translate('modal.duplicateAccount.contactNumber');
  const modalPrimaryBtnLabel: string = translate('modal.duplicateAccount.primaryBtnLabel');
  const modalSecondaryBtnLabel: string = translate('modal.duplicateAccount.secondaryBtnLabel');

  const ZARMessage: string = translate('modal.ZAR.heading');
  const ZARAdditionalMessage: string = translate('modal.ZAR.description');

  const ZARPrimaryBtnLabel: string = translate(
    isMobileLayout ? 'modal.ZAR.mobilePrimaryBtnLabel' : 'modal.ZAR.primaryBtnLabel'
  );

  const ZARSecondaryBtnLabel: string = translate(
    isMobileLayout ? 'modal.ZAR.mobileSecondaryBtnLabel' : 'modal.ZAR.secondaryBtnLabel'
  );

  const {
    eventAction: { click, load, select },
    enterYourDetails: {
      click: {
        continueButton,
        backButton,
        alreadyHaveAnAccountLogin,
        alreadyHaveAnAccountResetPassword,
        whyDoWeNeedThis
      },
      load: { enterYourDetailsPageLoad, alreadyHaveAnAccountLoad },
      select: { countryOfResidence: countryOfResidenceGA, occupation: occupationGA }
    }
  } = GoogleAnalyticsTagsMapping;

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(load, enterYourDetailsPageLoad);
  });

  useEffect(() => {
    const showTnCCheckBox: boolean =
      (isCurrentTenantPropertyPay || queryCORValue === USA || isETVExist) && !shouldApplyBrazilChanges;

    setShowTnCCheckBox(showTnCCheckBox);

    if (shouldApplyBrazilChanges) {
      setValue('agreeTC', null);
    } else {
      setValue('agreeTC', showTnCCheckBox ? !!agreeTC : null);
    }

    if (shouldApplyBrazilChanges && !addTaxStatementRef.current) {
      addTaxStatementRef.current = true;
      setTaxStatementIndex === -1 && dispatch(addTaxStatementStep());
      setPreferencesStepIndex !== -1 && dispatch(removePreferencesStep());
    }
  }, [isCurrentTenantPropertyPay, queryCORValue, isETVExist, CORValue]);

  useEffect(() => {
    if (isValidOTP && updateLeadStatus && verifyETVLoading === 'succeeded') {
      dispatch(resetVerifyETVLoading());
    }
  }, [isValidOTP, updateLeadStatus, verifyETVLoading]);

  useEffect(() => {
    if (etvDataFetchedRef.current) return;
    if (!isCurrentTenantPropertyPay) {
      return;
    }

    etvDataFetchedRef.current = true;

    if (currenciesOptionData.length === 0) {
      dispatch(getCurrencyData());
    }
    if (etvOptionData.length === 0) {
      dispatch(
        getEtaData({
          locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
        })
      );
    }
  }, [isCurrentTenantPropertyPay, currenciesOptionData, etvOptionData]);

  useEffect(() => {
    if (
      ipBasedCountryCode &&
      locationLookupLoading === 'succeeded' &&
      ipBasedCountryCode === ZAF &&
      !hasZARModalBeenOpened &&
      tenant &&
      SHOW_ZAR_MODAL_ORGANIZATIONS_LIST.includes(tenant) &&
      useReactLandingPage
    ) {
      setIsZARModalOpen(true);
      dispatch(updateZarModalOpenStatus(true));
    }
  }, [ipBasedCountryCode, locationLookupLoading]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    if (countryData.length > 0 && languageForCountryData === (language || LANG_CODE_MAPPING.EN)) {
      return;
    }
    dataFetchedRef.current = true;

    dispatch(
      getCountryData({
        locale: localeValue
      })
    );
  }, [countryLoading]);

  useEffect(() => {
    if (occupationDataFetchedRef.current || occupationData?.length > 0) return;
    occupationDataFetchedRef.current = true;

    dispatch(
      getOccupationData({
        locale: localeValue
      })
    );
  }, []);

  useEffect(() => {
    if (employmentDataFetchedRef.current || employmentStatusData?.length > 0 || !showEmploymentFields) return;
    employmentDataFetchedRef.current = true;

    dispatch(
      getEmploymentStatusData({
        locale: localeValue
      })
    );

    dispatch(
      getEmploymentIndustryData({
        locale: localeValue
      })
    );
  }, []);

  useEffect(() => {
    trigger();
  }, [dobValue?.day, dobValue?.month, dobValue?.year, stateLicenseStatusValue, addressValue, CORValue, trigger]);

  useEffect(() => {
    setValue('address', address);
  }, [address, setValue]);

  useEffect(() => {
    // check if the selected country is one of the countries with state options.
    // Check if country code from store is different from the selected one or
    // state data is empty

    if (
      COUNTRY_ID_WITH_STATE_DROPDOWN[trimmedCORValue as CountryCodesWithStateDropdown] &&
      (countryStateData.countryCode !== trimmedCORValue || countryStateData.stateInfo.length === 0) &&
      countryStateLoading !== 'loading'
    ) {
      const requestData = {
        countryCode: trimmedCORValue as CountryCodesWithStateDropdown,
        locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
      };

      // Dispatch action to make API call and fetch state details
      dispatch(getCountryStateData(requestData));
    }
  }, [trimmedCORValue, countryStateData, countryStateLoading]);

  useEffect(() => {
    // Create dropdown options for state
    if (countryStateData && countryStateData.stateInfo && countryStateData.stateInfo.length > 0) {
      const options: OptionType[] = countryStateData.stateInfo.map((data: StateInfo) => {
        return {
          value: data.code,
          label: data.displayName
        };
      });
      setStateDropdownOptions(options);
    }
  }, [countryStateData]);

  useEffect(() => {
    // State license - Check if the state value
    // matches with any records from countryStateData
    // If yes, then extract the value of cdLicenseStatus and set
    // it in RHF(stateLicenseStatus - key) from the matched record
    if (
      isValidLicenseStatusCountry &&
      countryStateData &&
      LICENSE_STATUS_COUNTRIES.includes(countryStateData.countryCode?.trim().toLowerCase()) &&
      countryStateData.stateInfo.length > 0 &&
      addressValue?.state
    ) {
      const stateData = getStateData(addressValue?.state);
      if (stateData) {
        const cdLicenseStatus: StateLicenseStatusType = stateData.cdLicenseStatus;

        const isStateLicenseCheckNotApplicable: boolean =
          isCFSBEnabled && !ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_DETAILS[tenant!].includes(trimmedCORValue);

        !isStateLicenseCheckNotApplicable && setValue('stateLicenseStatus', cdLicenseStatus);
      } else {
        setValue('stateLicenseStatus', 2);
      }
    }
  }, [isValidLicenseStatusCountry, countryStateData, addressValue?.state, setValue]);

  useEffect(() => {
    const noDuplicateAccount: boolean = isDuplicateActAdvanceLoading === 'succeeded' && !isDuplicateActAdvance;

    if (updateLeadLoading === 'idle') {
      if (isDuplicateActAdvance) {
        TriggerGoogleAnalyticsTag(load, alreadyHaveAnAccountLoad);
        setShowDuplicateEmailModal(isDuplicateActAdvance);
      }
      if (noDuplicateAccount) {
        const payloadData = {
          leadId,
          firstName,
          lastName,
          mobileNumber,
          countryCode: extractDialingCodeValue(countryCode),
          isFromEnterDetails: true,
          countryOfResidence,
          address,
          dob,
          occupation,
          usa,
          esp,
          bra: braValue
        };
        dispatch(updateLead(payloadData));
      }
    }

    if (updateLeadStatus && updateLeadLoading === 'succeeded') {
      if (noDuplicateAccount) {
        dispatch(resetDuplicateAccountAdvance());

        dispatch(
          updateCurrentStep({
            stepType:
              (isETVExist || isCurrentTenantPropertyPay) && !shouldApplyBrazilChanges ? 'confirming-you' : nextStepType,
            isLoading: (isETVExist || isCurrentTenantPropertyPay) && !shouldApplyBrazilChanges
          })
        );

        if (isETVExist) {
          if (shouldApplyBrazilChanges) {
            navigate(STEPS_ROUTE_MAPPING[nextStepType]);
          } else {
            dispatch(startRegistrationProcess());
            navigate(STEPS_ROUTE_MAPPING['identity']);
          }
        } else if (isCurrentTenantPropertyPay) {
          const { currencyFrom, currencyTo, amount } = getCurrencyPairAndEtvAmount(
            countryOfResidence,
            currentTenant,
            {},
            currenciesOptionData
          );

          const payload = {
            currencySelling: currencyFrom,
            currencyBuying: currencyTo,
            estTransactionValue: amount
          };

          dispatch(updateETVValue(payload));
          dispatch(startRegistrationProcess());
          navigate(STEPS_ROUTE_MAPPING['identity']);
        } else {
          navigate(STEPS_ROUTE_MAPPING[nextStepType]);
        }
      }
      if (isValidOTP || noDuplicateAccount) {
        dispatch(resetUpdateLeadLoading());
      }
    }
  }, [isDuplicateActAdvanceLoading, isDuplicateActAdvance, updateLeadStatus, updateLeadLoading, CORValue]);

  useEffect(() => {
    const cpfTaxIdNumberValue = braValue?.cpfTaxIdNumber ?? '';
    if (shouldApplyBrazilChanges && cpfTaxIdNumberValue !== '')
      setValue('bra.cpfTaxIdNumber', getCPFNumberFormatted(cpfTaxIdNumberValue));
  }, [braValue?.cpfTaxIdNumber, setValue, trigger]);

  const onSubmit: SubmitHandler<EnterDetailsType> = (data: EnterDetailsType) => {
    TriggerGoogleAnalyticsTag(click, continueButton);
    TriggerGoogleAnalyticsTag(select, countryOfResidenceGA, {
      COR: countryOfResidence
    });
    data.countryOfResidenceName = selectedCountryOfResidenceName;

    dispatch(addEnterDetails(data));

    const { dob: dobData } = data;

    const checkDuplicateAccountPayload = {
      firstNameInitial: firstName?.charAt(0),
      lastName: lastName,
      birthDate: `${dobData.year}-${dobData.month}-${dobData.day}`
    };
    dispatch(checkDuplicateAccountAdvanced(checkDuplicateAccountPayload));
  };

  const navigateToBackPage = () => {
    TriggerGoogleAnalyticsTag(click, backButton);
    dispatch(
      updateCurrentStep({
        stepType: previousStepType
      })
    );

    navigate(STEPS_ROUTE_MAPPING[previousStepType]);
  };

  const resetOccupationAndEmploymentFormFields = () => {
    resetField('occupation');
    setValue('occupation', '');
    resetField('employmentStatus');
    setValue('employmentStatus', '');
    resetField('employmentIndustry');
    setValue('employmentIndustry', '');
    setShowEmploymentIndustryDropdown(false);
  };

  const handleCORChange = (value: string) => {
    TriggerGoogleAnalyticsTag(select, countryOfResidenceGA, {
      COR: value
    });

    setValue('countryOfResidence', value);
    setValue('stateLicenseStatus', 2);
    setSelectedCountry(value);
    // Dispatch an action to call getAdditionalInfoFields only if the COR is USA or ESP
    const trimmedValue: string = value?.trim().toLowerCase();
    extraFieldCountries.includes(trimmedValue) && dispatch(getAdditionalInfoFields(value));
    dispatch(resetAddressData());
    setValue('address', address);

    const { shouldApplyBrazilChanges: isBrazilianChangeRequired } = checkIfBrazilChangesApplicable(tenant, value);

    if (value) {
      if (trimmedValue === USA) {
        const defaultUSAValue: USARegDetailsType = {
          socialSecurityNumber: ''
        };
        setValue('usa', defaultUSAValue);
        resetField('esp');
        resetField('bra');

        resetOccupationAndEmploymentFormFields();
      } else if (trimmedValue === ESP) {
        const defaultESPValue: ESPRegDetailsType = {
          nieNifTaxIdNumber: ''
        };
        setValue('esp', defaultESPValue);
        resetField('usa');
        resetField('bra');

        resetOccupationAndEmploymentFormFields();
      } else if (isBrazilianChangeRequired) {
        const defaultBRAValue: BRARegDetailsType = {
          cpfTaxIdNumber: ''
        };
        setValue('bra', defaultBRAValue);
        resetField('usa');
        resetField('esp');

        resetOccupationAndEmploymentFormFields();
      } else {
        resetField('usa');
        resetField('esp');
        resetField('bra');
      }

      if (isBrazilianChangeRequired) {
        dispatch(addTaxStatementStep());
        if (setPreferencesStepIndex !== -1) {
          dispatch(removePreferencesStep());
        }
      } else if (setTaxStatementIndex !== -1) {
        dispatch(removeTaxStatementStep());
        if (!isETVExist && !isCurrentTenantPropertyPay && setPreferencesStepIndex === -1) {
          dispatch(addPreferencesStep());
        }
      }

      if (
        value &&
        value === ZAF &&
        !hasZARModalBeenOpened &&
        tenant &&
        SHOW_ZAR_MODAL_ORGANIZATIONS_LIST.includes(tenant) &&
        useReactLandingPage
      ) {
        setIsZARModalOpen(true);
        dispatch(updateZarModalOpenStatus(true));
      }

      const currentCountryOfResidenceName: string = getCountryName(countryData, value);
      setSelectedCountryOfResidenceName(currentCountryOfResidenceName);
    }
  };

  const handleDropdownChange = (value: string) => {
    const stateData = getStateData(value);
    const isStateLicenseCheckNotApplicable: boolean =
      isCFSBEnabled && !ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_DETAILS[tenant!].includes(trimmedCORValue);

    if (stateData && !isStateLicenseCheckNotApplicable) {
      const cdLicenseStatus: StateLicenseStatusType = stateData.cdLicenseStatus;

      if (cdLicenseStatus === 1) {
        setIsPartialLocationWarningOpen(true);
      } else if (cdLicenseStatus === 0) {
        setIsNoLocationWarningOpen(true);
      }
    }
  };

  const handleEmploymentStatusChange = (value: string) => {
    TriggerGoogleAnalyticsTag(select, occupationGA, {
      occupation: value
    });
    const selectedEmploymentStatus: EmploymentStatusType | undefined = employmentStatusData.find(
      (empStatus) => empStatus.name === value
    );
    selectedEmploymentStatus && setShowEmploymentIndustryDropdown(selectedEmploymentStatus.isIndustryDependent);
    setValue('employmentIndustry', '');
  };

  const handleSetAddressValue = (currentAddress: AddressType) => {
    handleDropdownChange(currentAddress?.state);
  };

  const handleClose = () => {
    dispatch(resetDuplicateAccountAdvance());
    setShowDuplicateEmailModal(false);
  };

  const sendToLogin = () => {
    TriggerGoogleAnalyticsTag(click, alreadyHaveAnAccountLogin);
    window.open(loginPageURL, '_self');
  };

  const handleResetPassword = () => {
    TriggerGoogleAnalyticsTag(click, alreadyHaveAnAccountResetPassword);
    window.open(forgotPasswordURL, '_self');
  };

  const getStateData = (stateValue: string) => {
    return (
      countryStateData &&
      countryStateData.stateInfo &&
      countryStateData.stateInfo.length > 0 &&
      countryStateData.stateInfo.find(
        (data: StateInfo) => data?.code?.trim().toLowerCase() === stateValue?.trim().toLowerCase()
      )
    );
  };

  const handleOccupationBlur = () => {
    TriggerGoogleAnalyticsTag(select, occupationGA, {
      occupation: occupationValue
    });
  };

  const handleNeedSectionClick = () => {
    TriggerGoogleAnalyticsTag(click, whyDoWeNeedThis);
  };

  // Used to toggle the checkbox when the label is clicked
  const handleToggleCheckbox = () => {
    setValue('agreeTC', !agreeTCValue);
    trigger();
  };

  const getFieldHeading = (labelText: string) => {
    return <StyledFieldHeading variant={isDesktopLayout ? 'body1' : 'body2'} type="medium" text={labelText} />;
  };

  const navigateToCDSA = () => {
    window.open(CD_SA_URL, '_self');
  };

  const employmentSection: JSX.Element = showOccupationDropdown ? (
    <EmploymentOccupation
      getFieldHeading={getFieldHeading}
      control={control}
      occupation={occupation}
      handleOccupationChange={handleEmploymentStatusChange}
    />
  ) : !showEmploymentFields ? (
    <>
      {getFieldHeading(occupationHeading)}
      <ControllerElementWrapper
        name={occupationFieldName}
        control={control}
        defaultValue={occupation}
        placeholderLabel={occupationLabel}
        component={RPInput}
        handleBlur={handleOccupationBlur}
      />
    </>
  ) : (
    <EmploymentFields
      getFieldHeading={getFieldHeading}
      control={control}
      employmentStatus={employmentStatus}
      handleEmploymentStatusChange={handleEmploymentStatusChange}
      showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
      employmentIndustry={employmentIndustry}
    />
  );

  const loadingState: boolean =
    countryLoading === 'loading' ||
    employmentStatusLoading === 'loading' ||
    employmentIndustryLoading === 'loading' ||
    occupationDataLoading === 'loading' ||
    currenciesLoading === 'loading' ||
    etvLoading === 'loading';

  return loadingState ? (
    <RPLoader size="medium" type="primary" />
  ) : (
    <>
      {useReactLandingPage && (
        <RPConfirmationModal
          isOpen={isZARModalOpen}
          handleClose={() => setIsZARModalOpen(false)}
          message={ZARMessage}
          additionMessage={ZARAdditionalMessage}
          primaryBtnLabel={ZARPrimaryBtnLabel}
          handlePrimaryBtnClick={navigateToCDSA}
          secondaryBtnLabel={ZARSecondaryBtnLabel}
          handleSecondaryBtnClick={() => {
            setIsZARModalOpen(false);
          }}
          modalVariant="ZAR"
          size={isMobileLayout ? 'small' : 'normal'}
        />
      )}
      <RPConfirmationModal
        isOpen={showDuplicateEmailModal}
        message={modalMessage}
        additionMessage={modalAdditionalMessage}
        contactMessage={contactMessage}
        primaryBtnLabel={modalPrimaryBtnLabel}
        secondaryBtnLabel={modalSecondaryBtnLabel}
        handleClose={() => {
          handleClose();
        }}
        handlePrimaryBtnClick={() => {
          handleClose();
          sendToLogin();
        }}
        handleSecondaryBtnClick={() => {
          handleClose();
          handleResetPassword();
        }}
        modalVariant="duplicateAccount"
        size={isMobileLayout ? 'small' : 'normal'}
      />

      <StyledHeading variant={isDesktopLayout ? 'h2' : 'h3'} type="bold" text={heading} layout={layout} />
      {isMobileLayout && <RPLinearProgressBar value={currentStepProgress} />}
      <StyledDescription
        variant="body2"
        type={isDesktopLayout ? 'medium' : 'normal'}
        text={description}
        layout={layout}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper
          layout={layout}
          shouldShowEmploymentFields={showEmploymentFields}
          showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
        >
          {getFieldHeading(addressSearchHeading)}
          <ControllerElementWrapper
            name={countryOfResidenceFieldName}
            control={control}
            defaultValue={countryOfResidence}
            placeholderLabel={countryOfResidenceLabel}
            component={RPSelectNew}
            size="normal"
            type="country"
            handleChange={(value: string) => handleCORChange(value)}
            showMaxOptions={!isMobileLayoutApplicable(layout)}
          />

          <RHFRPAddressLookup
            control={control}
            selectedCountry={selectedCountryForSearch}
            defaultValue={address}
            addressValue={addressValue}
            setValue={setValue}
            dropdownOptions={stateDropdownOptions}
            handleDropdownChange={handleDropdownChange}
            handleSetAddressValue={handleSetAddressValue}
          />

          {/* Warning or error message for state service */}
          {(isNoServiceLocation || isPartialServiceLocation) && (
            <LocationStatusMessage
              selectedState={getStateName(addressValue?.state, stateDropdownOptions)}
              type={isPartialServiceLocation ? 'warning' : 'error'}
            />
          )}

          {/* Partial service modal */}
          <PartialServiceModal
            isOpen={isPartialLocationWarningOpen}
            handleClose={() => setIsPartialLocationWarningOpen(false)}
            selectedState={getStateName(addressValue?.state, stateDropdownOptions)}
            size={isMobileLayout ? 'small' : 'normal'}
          />

          {/* No service modal */}
          <NoServiceModal
            isOpen={isNoLocationWarningOpen}
            handleClose={() => setIsNoLocationWarningOpen(false)}
            selectedState={getStateName(addressValue?.state, stateDropdownOptions)}
            size={isMobileLayout ? 'small' : 'normal'}
            selectedCountry={selectedCountryForSearch}
          />

          {getFieldHeading(dobHeading)}

          <RHFRPDoB
            defaultValue={dob}
            control={control}
            fieldNames={[dobDayFieldName, dobMonthFieldName, dobYearFieldName]}
            dateFormat={corIsUSA ? 'middle-endian' : 'little-endian'}
            orientation={isDesktopLayout ? 'horizontal' : 'vertical'}
            error={dobError}
          />

          {/* USA */}
          <Collapse easing="300" in={corIsUSA}>
            {corIsUSA && (
              <USADetails
                control={control}
                watch={watch}
                getFieldHeading={getFieldHeading}
                employmentStatus={employmentStatus}
                handleEmploymentStatusChange={handleEmploymentStatusChange}
                showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
                employmentIndustry={employmentIndustry}
              />
            )}
          </Collapse>

          {/* SPAIN */}
          <Collapse easing="300" in={corIsSpain}>
            {corIsSpain && (
              <ESPDetails
                control={control}
                watch={watch}
                getFieldHeading={getFieldHeading}
                employmentStatus={employmentStatus}
                handleEmploymentStatusChange={handleEmploymentStatusChange}
                showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
                employmentIndustry={employmentIndustry}
              />
            )}
          </Collapse>

          {/* BRAZIL */}
          <Collapse easing="300" in={corIsBrazil}>
            {shouldApplyBrazilChanges && (
              <BRADetails
                control={control}
                watch={watch}
                getFieldHeading={getFieldHeading}
                employmentStatus={employmentStatus}
                handleEmploymentStatusChange={handleEmploymentStatusChange}
                showEmploymentIndustryDropdown={showEmploymentIndustryDropdown}
                employmentIndustry={employmentIndustry}
              />
            )}
          </Collapse>

          {CORValue && !extraFieldCountries.includes(trimmedCORValue) && (
            <>
              {employmentSection}

              <WhyDoWeNeedThisSection handleClick={handleNeedSectionClick} />
            </>
          )}

          {showTnCCheckBox && (
            <AgreeTnCContainer>
              <AgreeTnCPrivacyPolicy
                control={control}
                defaultValue={agreeTC}
                toggleCheckbox={handleToggleCheckbox}
                termsAndConditionsLink={tncLink}
                privacyPolicyLink={privacyPolicyLink}
              />
            </AgreeTnCContainer>
          )}

          <RPButtonContainer layout={layout}>
            <RPButton
              size={buttonSize}
              type="submit"
              disabled={isContinueBtnDisabled || showLoader}
              endIconType={showLoader ? 'loader' : ''}
              name={continueButtonName}
              data-testid={continueButtonDataTestId}
            >
              {continueButtonLabel}
            </RPButton>
            <RPButton
              size={buttonSize}
              btnType="text"
              onClick={navigateToBackPage}
              name={backButtonName}
              data-testid={backButtonDataTestId}
            >
              {backButtonLabel}
            </RPButton>
          </RPButtonContainer>
        </FormWrapper>
      </form>
    </>
  );
};

export default EnterDetails;
