import { FC, memo, useContext, useMemo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
import { RPText } from '../../atoms/RPText';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { CD_REGULATORY_INFORMATION_LINK, EN_US } from '../../../core/utils/Constants/Constants';
import RPLink from '../../atoms/RPLink';

type SizeProps = {
  size?: 'large' | 'medium';
};

const StyledSocialProof = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFromLandingOrRegister'
})<SocialProofProps>(({ theme, isFromLandingOrRegister }) => {
  const {
    colours: {
      text: { textLandingPage, textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_LANDING_PAGE: HexColour = textLandingPage;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  return {
    maxWidth: '475px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '13px',

    '& .MuiTypography-root': {
      color: isFromLandingOrRegister ? COLOUR_TEXT_TEXT_LANDING_PAGE : COLOUR_TEXT_TEXT_INVERSE
    },

    '> svg > g > path:first-of-type': {
      fill: isFromLandingOrRegister ? COLOUR_TEXT_TEXT_LANDING_PAGE : COLOUR_TEXT_TEXT_INVERSE
    }
  };
});

interface SocialProofHeadingProps extends BoxProps, SizeProps {}

const SocialProofHeading = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<SocialProofHeadingProps>(({ size }) => {
  const isMediumSize: boolean = size === 'medium';

  return {
    marginLeft: 7,
    marginTop: isMediumSize ? 7 : 4
  };
});

interface StyledLicenseInfoLinkProps {
  isFromLandingOrRegister: boolean;
}

const StyledLicenseInfoLink = styled(RPLink, {
  shouldForwardProp: (prop) => prop !== 'isFromLandingOrRegister'
})<StyledLicenseInfoLinkProps>(({ theme, isFromLandingOrRegister }) => {
  const {
    colours: {
      text: { textLandingPage, textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_LANDING_PAGE: HexColour = textLandingPage;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  return {
    textDecorationColor: isFromLandingOrRegister ? COLOUR_TEXT_TEXT_LANDING_PAGE : COLOUR_TEXT_TEXT_INVERSE,
    '&:hover': {
      color: isFromLandingOrRegister ? COLOUR_TEXT_TEXT_LANDING_PAGE : COLOUR_TEXT_TEXT_INVERSE
    }
  };
});

interface SocialProofProps extends SizeProps {
  isFromLandingOrRegister?: boolean;
  removeTrustPilot?: boolean
}

const SocialProof: FC<SocialProofProps> = (props: SocialProofProps) => {
  const { translations, language } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const SocialProofIcon = useMemo(() => getIcon(tenant, 'socialProof'), [tenant]);

  const { size = 'large', isFromLandingOrRegister = false, removeTrustPilot = false } = props;

  const isMediumSize: boolean = size === 'medium';
  const isEnUs: boolean = language?.trim().toLowerCase() === EN_US;

  const heading: string = translate('common.socialProof.heading');
  const subHeading: string = translate('common.socialProof.subHeading');
  const description: string = translate('common.socialProof.description');
  const licenseInfoText: string = translate('common.socialProof.licenseInfo');
  const authorisationDescription: string = translate('common.socialProof.authorisationDescription');

  const currentDescription: string = removeTrustPilot ? authorisationDescription : description;

  return (
    <StyledSocialProof isFromLandingOrRegister={isFromLandingOrRegister} size={size}>
      {!removeTrustPilot &&
        <>
          <SocialProofIcon />
          <SocialProofHeading size={size}>
            <RPText variant={isMediumSize ? 'body1' : 'h3'} type="bold" text={heading} />
            <RPText variant={isMediumSize ? 'body2' : 'body1'} type="normal" text={subHeading} />
          </SocialProofHeading>
        </>
      }
      <div>
        <RPText variant={isMediumSize ? 'body2' : 'body1'} type="normal" text={currentDescription} />
        {/* this is only for EN_US */}
        {isEnUs && (
          <StyledLicenseInfoLink
            variant={isMediumSize ? 'body2' : 'body1'}
            underline="always"
            type="normal"
            text={licenseInfoText}
            href={CD_REGULATORY_INFORMATION_LINK}
            target="_blank"
            data-testid="rp-link-license-info"
            isFromLandingOrRegister={isFromLandingOrRegister}
          />
        )}
      </div>
    </StyledSocialProof>
  );
};

export default memo(SocialProof);
