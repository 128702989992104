import { createAction } from '@reduxjs/toolkit';

import {
  RESET_STORE_TO_INITIAL_STATE,
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE,
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FOR_COR_BRAZIL_IN_CD_BRAND
} from '../ActionTypeConstants';

export const resetStoreToInitialState = createAction<string>(RESET_STORE_TO_INITIAL_STATE);
export const resetStoreToInitialStateFromLandingPage = createAction<string>(
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE
);
export const resetStoreToInitialStateForCorBrazilInCDBrand = createAction<string>(
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FOR_COR_BRAZIL_IN_CD_BRAND
);
