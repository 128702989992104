import { useEffectOnce } from 'usehooks-ts';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';

// Contexts
import { TenantContext } from '../TenantProvider/contexts';
// RTK Slice
import { resetAuthorisationDetails } from '../../redux/modules/authorisationDetailsSlice';
import { resetErrorDetails } from '../../redux/modules/errorDetailsSlice';
import { resetLoginDetails } from '../../redux/modules/loginDetailsSlice';
import { getRegistrationDetails, resetRegistrationDetails } from '../../redux/modules/registrationDetailsSlice';
import { resetRegistrationStepsDetails } from '../../redux/modules/registrationStepsSlice';
// Redux
import { resetStoreToInitialStateForCorBrazilInCDBrand } from '../../redux/actions/resetStore';
// Utils
import { checkIfBrazilChangesApplicable } from '../utils/CheckIfBrazilChangesApplicable';
//CONSTANTS
import {
  RESET_AUTHORISATION_DETAILS,
  RESET_ERROR_DETAILS,
  RESET_LOGIN_DETAILS,
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FOR_COR_BRAZIL_IN_CD_BRAND,
  RESET_REGISTRATION_DETAILS,
  RESET_REGISTRATION_STEPS_DETAILS
} from '../../redux/ActionTypeConstants';

const useResetStore = () => {
  const dispatch: any = useDispatch();

  const { tenant } = useContext(TenantContext);

  const { countryOfResidence } = useSelector(getRegistrationDetails);

  const { shouldApplyBrazilChanges } = checkIfBrazilChangesApplicable(tenant, countryOfResidence);

  useEffectOnce(() => {
    if (shouldApplyBrazilChanges) {
      dispatch(
        resetStoreToInitialStateForCorBrazilInCDBrand(RESET_PARTIAL_STORE_TO_INITIAL_STATE_FOR_COR_BRAZIL_IN_CD_BRAND)
      );
    } else {
      dispatch(resetAuthorisationDetails(RESET_AUTHORISATION_DETAILS));
      dispatch(resetErrorDetails(RESET_ERROR_DETAILS));
      dispatch(resetLoginDetails(RESET_LOGIN_DETAILS));
      dispatch(resetRegistrationDetails(RESET_REGISTRATION_DETAILS));
      dispatch(resetRegistrationStepsDetails(RESET_REGISTRATION_STEPS_DETAILS));
    }
  });
};

export default useResetStore;
