import { createAsyncThunk } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import axiosWrapper from './axiosWrapper';
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import GetCookieDataObject from '../../core/utils/GetCookieDataObject';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import { DoBType, ESPRegDetailsType, USARegDetailsType } from '../../core/types/RegistrationStepForm';
import { AddressType } from '../../core/types/addressLookupTypes';
import { extractDialingCodeValue } from '../../core/utils/DialingData';
import { featureFlags } from '../../core/FeatureFlags/FeatureFlags';

const {
  orgCodeID,
  orgCode,
  leadSource,
  accountType,
  parentOrganizationCode,
  siteId
} = GetBasicDetails();

type registrationLeadPayload = {
  currencyFrom: string;
  currencyTo: string;
  email: string;
  countryCode: string;
  mobileNumber: string;
  leadId: string;
  occupation: string;
  employmentStatus: string;
  employmentIndustry: string;
  password: string;
  amount: string;
  countryOfResidence: string;
  countryOfResidenceName: string;
  address: AddressType;
  dob: DoBType;
  usa: USARegDetailsType | null;
  esp: ESPRegDetailsType | null;
  referralCodeOrName: string;
  locale: string;
};

const addRegistrationLead = createAsyncThunk(
  'registration-service/v1/addRegistrationLead',
  async (data: registrationLeadPayload, thunkAPI) => {
    // Hard-coding the IP to London, UK until the changes for POP-8106 are done
    // const IPData: string = '103.204.124.0';

    const {
      currencyFrom,
      currencyTo,
      email,
      countryCode,
      mobileNumber,
      leadId,
      occupation,
      employmentStatus,
      employmentIndustry,
      password,
      amount,
      countryOfResidenceName,
      address,
      dob,
      referralCodeOrName,
      locale
    } = data;

    const { addressLine1, addressLine2, addressLine3, province, townCity, suburbCity, county, state, postcode } = address || {};

    const birthdate: string = dob.day.length === 1 ? '0' + dob.day : dob.day;
    const birthMonth: string = dob.month.length === 1 ? '0' + dob.month : dob.month;
    const birthdateValue: string = `${dob.year}-${birthMonth}-${birthdate}`;

    const { cookiesData, ppcData } = GetCookieDataObject('addRegistrationLead');

    const {
      ppcData: { allowPPCCookies },
      enterDetails: { showEmploymentFields }
    } = featureFlags;

    const isCookiesDataEmpty = isEmpty(cookiesData);
    const isPPCDataEmpty = isEmpty(ppcData);

    const payload = {
      accountType: accountType,
      countryofResidence: countryOfResidenceName,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressLine3: addressLine3,
      city: townCity,
      suburb: suburbCity,
      town: townCity,
      province: province,
      state: state,
      county: county,
      postcode: postcode,
      // externalIP: IPData,
      leadId: leadId,
      locale: locale,
      mobilePhone: `${extractDialingCodeValue(countryCode)}-${mobileNumber}`,
      ...(showEmploymentFields
        ? {
            employmentStatus: employmentStatus,
            employmentIndustry: employmentIndustry
          }
        : {
            occupation: occupation
          }),

      organizationCode: orgCode,
      password: password,
      siteID: siteId,
      source: leadSource,
      //sending currencyFrom as currency buying and currencyTo as currency selling in all brands
      currencySelling: currencyTo,
      currencyBuying: currencyFrom,
      parentOrgCode: parentOrganizationCode,
      estTransactionValue: amount,
      organizationID: orgCodeID,
      birthDate: birthdateValue,
      eMail: email,
      referredByText: referralCodeOrName,
      ...(!isCookiesDataEmpty && { cookiesData: cookiesData }),
      ...(allowPPCCookies && !isPPCDataEmpty && { ppcData: ppcData })
    };

    try {
      const response = await axiosWrapper('addRegistrationLeadV2', 'POST', thunkAPI, payload);

      return response && response.data;
    } catch (error: any) {
      error.statusCode = error.response.status;
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export default addRegistrationLead;
