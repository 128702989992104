import { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import RPButton, { RPButtonContainer } from '../../../atoms/RPButton';
import RPUploadFileSection from '../../../molecules/RPUploadFileSection';
import RPUploadedFileDetails from '../../../molecules/RPUploadedFileDetails';
import AgreeTnCPrivacyPolicy from '../../../molecules/AgreeTnCPrivacyPolicy';
// Validation Schema or files in the same folder
import { ValidationSchema } from './ValidationSchema';
import { validateFileExtension, validateFileSize } from '../ConfirmingYou/ValidationSchema';
// Hooks
import { useStepTypeData } from '../../../../core/hooks';
// Types
import { LayoutProps } from '../../../../core/types/LayoutProps';
import { TaxStatementType } from '../../../../core/types/RegistrationStepForm';
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LinkType } from '../../../../core/types/GoogleAnalyticsTypes';
// RTK Slice
import { disableEditIconForSteps, updateCurrentStep } from '../../../../redux/modules/registrationStepsSlice';
import {
  addPreferanceData,
  getRegistrationDetails,
  startRegistrationProcess,
  resetSaveDocumentLoading
} from '../../../../redux/modules/registrationDetailsSlice';
// API Wrappers
import saveDocuments, { TaxStatementDocumentData } from '../../../../redux/api/saveDocuments';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { getTnCPrivacyPolicyLinks } from '../../../../core/utils/GetTnCPrivacyPolicyLinks';
import getRoundedFileSize from '../../../../core/utils/GetRoundedFileSize';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
// Constants
import { BRA_COR_ETV_DEFAULT_VALUE, STEPS_ROUTE_MAPPING } from '../../../../core/utils/Constants/Constants';
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';

interface StyledHeadingProps extends LayoutProps {}
interface StyledDescriptionProps extends LayoutProps {}

const StyledHeading = styled(RPText)<StyledHeadingProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '15px' : '20px',
    color: COLOUR_TEXT_TEXT
  };
});

const StyledDescription = styled(RPText)<StyledDescriptionProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '15px' : '20px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const AgreeTnCContainer = styled('div')(() => {
  return {
    marginTop: '40px'
  };
});

const ImageUploadHeading = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '10px'
  };
});

const TaxStatement = () => {
  const { layout } = useContext(LayoutContext);
  const { translations, language } = useContext(LanguageContext);
  const dispatch: any = useDispatch();

  const navigate = useNavigate();
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const registrationDetails = useSelector(getRegistrationDetails);
  const { email, leadId, agreeTC, isETVExist, saveDocumentLoading } = registrationDetails;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [uploadedFileSize, setUploadedFileSize] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const isDesktopLayout: boolean = layout === 'desktop';

  const headingVariant: 'h2' | 'h3' = isDesktopLayout ? 'h2' : 'h3';
  const descriptionType: 'medium' | 'normal' = isDesktopLayout ? 'medium' : 'normal';

  const buttonSize: 'medium' | 'large' = isDesktopLayout ? 'large' : 'medium';

  const headingText: string = translate('registration.taxStatement.heading');
  const descriptionText: string = translate('registration.taxStatement.description');
  const uploadDocHeading: string = translate('registration.taxStatement.file.heading');
  const continueButtonLabel: string = translate('registration.taxStatement.continueButtonText');
  const backButtonLabel: string = translate('registration.taxStatement.backButtonText');

  const firstFilePoaAvailableFileTypes: string = translate('registration.taxStatement.file.availableFileTypes');
  const firstFilePoaMaxFileSizeText: string = translate('registration.taxStatement.file.maxFileSizeText');
  const maxFileSizeErrorText: string = translate('registration.taxStatement.file.error.maxFileSize');
  const allowedFileTypesErrorText: string = translate('registration.taxStatement.file.error.allowedFileTypes');

  const { termsAndConditions: tncLink, privacyPolicy: privacyPolicyLink } = getTnCPrivacyPolicyLinks(tenant, language);

  const { previousStepType, nextStepType } = useStepTypeData('tax-statement');

  const {
    taxStatement: {
      continueButton: { name: continueButtonName, dataTestId: continueButtonDataTestId },
      backButton: { name: backButtonName, dataTestId: backButtonDataTestId }
    }
  } = REGISTRATION_DEFINITIONS;

  const {
    eventAction: { click },
    taxStatement: {
      click: { tnCLinkClick, privacyPolicyLinkClick, submitDocumentsClick, browseFileClick }
    }
  } = GoogleAnalyticsTagsMapping;

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { isValid }
  } = useForm<TaxStatementType>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      agreeTC: agreeTC,
      taxStatementDocument: null,
      fileName: ''
    },
    resolver: yupResolver(ValidationSchema())
  });

  const { agreeTC: agreeTCValue } = watch();

  const handleToggleCheckbox = () => {
    setValue('agreeTC', !agreeTCValue);
    trigger();
  };

  const handleFileUpload = (files: FileList) => {
    const fileUploaded = files[0];
    const fileName = fileUploaded.name;
    const roundedFileSize: string = getRoundedFileSize(fileUploaded.size);

    TriggerGoogleAnalyticsTag(click, browseFileClick);

    setUploadedFileName(fileName);
    setUploadedFileSize(roundedFileSize);
    setUploadedFile(fileUploaded);
    setValue('taxStatementDocument', fileUploaded);
    setValue('fileName', fileName);

    const isFileSizeValid = validateFileSize(files[0]);
    const isFileExtensionValid = validateFileExtension(files[0]);

    if (!isFileSizeValid || !isFileExtensionValid) {
      removeUploadedFile();
      !isFileSizeValid && setErrorMessage(maxFileSizeErrorText);
      !isFileExtensionValid && setErrorMessage(allowedFileTypesErrorText);
    }
    if (isFileSizeValid && isFileExtensionValid) {
      setErrorMessage(null);
    }
    trigger();
  };

  const removeUploadedFile = () => {
    setUploadedFileName('');
    setUploadedFileSize('');
    setUploadedFile(null);
    setValue('taxStatementDocument', null);
    setValue('fileName', '');
    trigger();
  };

  useEffect(() => {
    if (saveDocumentLoading === 'succeeded') {
      if (!isETVExist) {
        const etvPayload = {
          currencyFrom: BRA_COR_ETV_DEFAULT_VALUE.currencyFrom,
          currencyTo: BRA_COR_ETV_DEFAULT_VALUE.currencyTo,
          amount: BRA_COR_ETV_DEFAULT_VALUE.amount
        };
        dispatch(addPreferanceData(etvPayload));
      }
      dispatch(disableEditIconForSteps());
      dispatch(
        updateCurrentStep({
          stepType: nextStepType,
          isLoading: true
        })
      );
      dispatch(startRegistrationProcess());
      dispatch(resetSaveDocumentLoading());
      navigate(STEPS_ROUTE_MAPPING['identity']);
    }
  }, [saveDocumentLoading]);

  const onSubmit: SubmitHandler<TaxStatementType> = (data) => {
    TriggerGoogleAnalyticsTag(click, submitDocumentsClick);

    const documentPayload: TaxStatementDocumentData = {
      taxStatementDocument: data.taxStatementDocument,
      leadId,
      email
    };

    dispatch(saveDocuments(documentPayload));
  };

  const navigateToBackPage = () => {
    dispatch(
      updateCurrentStep({
        stepType: previousStepType
      })
    );

    navigate(STEPS_ROUTE_MAPPING[previousStepType]);
  };

  const linkClick = (type: LinkType) => {
    TriggerGoogleAnalyticsTag(click, type === LinkType.PP ? privacyPolicyLinkClick : tnCLinkClick);
  };

  const errorShow: any = errorMessage ? errorMessage : '';

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledHeading variant={headingVariant} type="bold" text={headingText} layout={layout} />
        <StyledDescription variant="body2" type={descriptionType} text={descriptionText} layout={layout} />
        <ImageUploadHeading variant="body2" type="normal" text={uploadDocHeading} />
        {uploadedFileName && uploadedFileName.length > 0 ? (
          <RPUploadedFileDetails
            fileName={uploadedFileName}
            fileSize={uploadedFileSize}
            removeUploadedFileHandler={removeUploadedFile}
          />
        ) : (
          <RPUploadFileSection
            handleFileUpload={(files: FileList) => handleFileUpload(files)}
            value={uploadedFile}
            availableFileTypesText={firstFilePoaAvailableFileTypes}
            maxFileSizeText={firstFilePoaMaxFileSizeText}
            error={errorShow}
          />
        )}

        <AgreeTnCContainer>
          <AgreeTnCPrivacyPolicy
            control={control}
            defaultValue={agreeTC}
            linkClickHandler={linkClick}
            toggleCheckbox={handleToggleCheckbox}
            termsAndConditionsLink={tncLink}
            privacyPolicyLink={privacyPolicyLink}
          />
        </AgreeTnCContainer>
        <RPButtonContainer layout={layout}>
          <RPButton
            size={buttonSize}
            type="submit"
            disabled={!isValid}
            name={continueButtonName}
            data-testid={continueButtonDataTestId}
          >
            {continueButtonLabel}
          </RPButton>
          <RPButton
            size={buttonSize}
            btnType="text"
            onClick={navigateToBackPage}
            name={backButtonName}
            data-testid={backButtonDataTestId}
          >
            {backButtonLabel}
          </RPButton>
        </RPButtonContainer>
      </form>
    </>
  );
};

export default TaxStatement;
