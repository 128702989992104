import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Contexts
import { LanguageContext, TenantContext } from '../TenantProvider/contexts';
// RTK Slice
import { getReferenceData, setSelectedLanguage } from '../../redux/modules/referenceDataSlice';
import { getAuthorisationDetails } from '../../redux/modules/authorisationDetailsSlice';
// API Wrappers
import getLocationLookup from '../../redux/api/getLocationLookup';
// Utils
import { CreateCookie as createCookie, GetCookie as getCookie } from '../utils/CreateCookie';

// Constants
import {
  LANG_CODE_MAPPING,
  LOCALE_LANG_CODE_MAPPING,
  EN_US,
  USA,
  ORGANIZATION_CODE_APPLICABLE_LOCALES_MAPPING,
  LANGUAGE_COOKIE_OPTION,
  LANGUAGE_COOKIE_NAME
} from '../utils/Constants/Constants';

const useLanguageCookieAndIPCheck = (shouldCallAfterCountryAPI: boolean = false) => {
  const { tenant } = useContext(TenantContext);

  const { language, setLanguage } = useContext(LanguageContext);

  const dispatch: any = useDispatch();

  let locationLookupFetchedRef = useRef(false);
  let currentCookieFetchedRef = useRef(false);

  const { ipBasedCountryCode, locationLookupLoading, countryLoading } = useSelector(getReferenceData);
  const { generateTokenLoading } = useSelector(getAuthorisationDetails);

  const currentLanguageCookie: string = getCookie(LANGUAGE_COOKIE_NAME) || '';

  // country code from location-lookup if queryCOR not available
  useEffect(() => {
    if (locationLookupFetchedRef.current) return;
    if (shouldCallAfterCountryAPI) {
      if (ipBasedCountryCode === '' && generateTokenLoading === 'succeeded' && countryLoading === 'succeeded') {
        locationLookupFetchedRef.current = true;
        dispatch(getLocationLookup());
      }
    } else {
      if (ipBasedCountryCode === '' && generateTokenLoading === 'succeeded') {
        locationLookupFetchedRef.current = true;
        dispatch(getLocationLookup());
      }
    }
  }, [generateTokenLoading, countryLoading]);

  useEffect(() => {
    if (currentCookieFetchedRef.current) return;
    currentCookieFetchedRef.current = true;

    const EN_GB: string = LANG_CODE_MAPPING.EN;
    const currentCookieLocaleValue = LANG_CODE_MAPPING[currentLanguageCookie?.toUpperCase()];
    const IsCookieLangValueApplicableForOrganisation: boolean =
      ORGANIZATION_CODE_APPLICABLE_LOCALES_MAPPING?.[tenant!]?.includes(currentCookieLocaleValue);

    if (
      currentLanguageCookie &&
      (currentCookieLocaleValue !== (language || EN_GB) || IsCookieLangValueApplicableForOrganisation)
    ) {
      const currentLocale: string = IsCookieLangValueApplicableForOrganisation ? currentCookieLocaleValue : EN_GB;

      setLanguage(currentLocale);
      dispatch(setSelectedLanguage(currentLocale));
      createCookie(LANGUAGE_COOKIE_NAME, IsCookieLangValueApplicableForOrganisation ? currentLanguageCookie : LOCALE_LANG_CODE_MAPPING[EN_GB], LANGUAGE_COOKIE_OPTION);
    }
  }, []);

  useEffect(() => {
    if (!currentLanguageCookie) {
      const isLocationLookupLoadingSucceeded: boolean = locationLookupLoading === 'succeeded';
      const isLocationLookupLoadingFailed: boolean = locationLookupLoading === 'failed';
      if (
        isLocationLookupLoadingSucceeded &&
        ipBasedCountryCode?.trim().toLowerCase() === USA &&
        ORGANIZATION_CODE_APPLICABLE_LOCALES_MAPPING?.[tenant!]?.includes(EN_US)
      ) {
        setLanguage(EN_US);
        dispatch(setSelectedLanguage(EN_US));
        createCookie(LANGUAGE_COOKIE_NAME, LOCALE_LANG_CODE_MAPPING[EN_US], LANGUAGE_COOKIE_OPTION);
      } else if (isLocationLookupLoadingSucceeded || isLocationLookupLoadingFailed) {
        setLanguage(LANG_CODE_MAPPING['EN']);
        dispatch(setSelectedLanguage(LANG_CODE_MAPPING['EN']));
        createCookie(LANGUAGE_COOKIE_NAME, 'en', LANGUAGE_COOKIE_OPTION);
      }
    }
  }, [ipBasedCountryCode, locationLookupLoading]);
};

export default useLanguageCookieAndIPCheck;
