import { RegistrationDefinitionsType } from '../../../core/types/RegistrationDefinitions';

export const REGISTRATION_DEFINITIONS: RegistrationDefinitionsType = {
  createLogin: {
    firstName: {
      name: 'firstName',
      dataTestId: 'rp-input-first-name'
    },
    lastName: {
      name: 'lastName',
      dataTestId: 'rp-input-last-name'
    },
    email: {
      name: 'email',
      dataTestId: 'rp-input-email'
    },
    password: {
      name: 'password',
      dataTestId: 'rp-input-password'
    },
    referred: {
      name: 'referred',
      dataTestId: 'rp-radio-button-referred'
    },
    referralCodeOrName: {
      name: 'referralCodeOrName',
      dataTestId: 'rp-input-referral-code-name'
    },
    submitButton: {
      name: 'createLoginContinueButton',
      dataTestId: 'rp-button-create-login-continue'
    }
  },
  setupSecurity: {
    mobileNumberScreen: {
      countryCode: {
        name: 'countryCode',
        dataTestId: 'rp-select-country-code'
      },
      mobileNumber: {
        name: 'mobileNumber',
        dataTestId: 'rp-input-mobile-number'
      },
      continueButton: {
        name: 'mobileNumberScreenContinueButton',
        dataTestId: 'rp-button-mobile-number-screen-continue'
      },
      backButton: {
        name: 'mobileNumberScreenBackButton',
        dataTestId: 'rp-button-mobile-number-screen-back'
      }
    },
    securityPinScreen: {
      securityPin: {
        name: 'securityPin',
        dataTestId: 'rp-pin-input-security-pin'
      },
      SMSOrCallSection: {
        receiveCallButton: {
          name: 'receiveCallButton',
          dataTestId: 'rp-button-receive-call'
        },
        receiveSMSButton: {
          name: 'receiveSMSButton',
          dataTestId: 'rp-button-receive-sms'
        },
        resendSMSButton: {
          name: 'resendSMSButton',
          dataTestId: 'rp-button-resend-sms'
        }
      },
      continueButton: {
        name: 'securityPinScreenContinueButton',
        dataTestId: 'rp-button-security-pin-screen-continue'
      },
      backButton: {
        name: 'securityPinScreenBackButton',
        dataTestId: 'rp-button-security-pin-screen-back'
      },
      troubleReceivingCode: {
        dataTestId: 'rp-link-trouble-receiving-code'
      }
    },
    completedScreen: {
      continueButton: {
        name: 'verifiedScreenContinueButton',
        dataTestId: 'rp-button-verified-screen-continue'
      },
      backButton: {
        name: 'verifiedScreenBackButton',
        dataTestId: 'rp-button-verified-screen-back'
      },
      changeNumber: {
        dataTestId: 'rp-link-change-number'
      }
    }
  },
  enterDetails: {
    countryOfResidence: {
      name: 'countryOfResidence',
      dataTestId: 'rp-select-country-of-residence'
    },
    addressSearch: {
      name: 'addressSearch',
      dataTestId: 'rp-input-address-search'
    },
    addressLine1: {
      name: 'address.addressLine1',
      dataTestId: 'rp-input-address-line1'
    },
    addressLine2: {
      name: 'address.addressLine2',
      dataTestId: 'rp-input-address-line2'
    },
    addressLine3: {
      name: 'address.addressLine3',
      dataTestId: 'rp-input-address-line3'
    },
    suburbCity: {
      name: 'address.suburbCity',
      dataTestId: 'rp-input-suburb-city'
    },
    province: {
      name: 'address.province',
      dataTestId: 'rp-input-province'
    },
    townCity: {
      name: 'address.townCity',
      dataTestId: 'rp-input-town-city'
    },
    county: {
      name: 'address.county',
      dataTestId: 'rp-input-county'
    },
    state: {
      name: 'address.state',
      dataTestId: 'rp-select-state'
    },
    postcode: {
      name: 'address.postcode',
      dataTestId: 'rp-input-postcode'
    },
    dob: {
      day: {
        name: 'dob.day',
        dataTestId: 'rp-input-dob-day'
      },
      month: {
        name: 'dob.month',
        dataTestId: 'rp-input-dob-month'
      },
      year: {
        name: 'dob.year',
        dataTestId: 'rp-input-dob-year'
      }
    },
    usa: {
      socialSecurityNumber: {
        name: 'usa.socialSecurityNumber',
        dataTestId: 'rp-input-usa-social-security-number'
      }
    },
    esp: {
      nieNifTaxIdNumber: {
        name: 'esp.nieNifTaxIdNumber',
        dataTestId: 'rp-input-esp-nie-nif-tax-id-number'
      }
    },
    bra: {
      cpfTaxIdNumber: {
        name: 'bra.cpfTaxIdNumber',
        dataTestId: 'rp-input-bra-cpf-tax-id-number'
      }
    },
    occupation: {
      name: 'occupation',
      dataTestId: 'rp-input-occupation'
    },
    employmentStatus: {
      name: 'employmentStatus',
      dataTestId: 'rp-select-employment-status'
    },
    employmentIndustry: {
      name: 'employmentIndustry',
      dataTestId: 'rp-select-industry-working-in'
    },
    continueButton: {
      name: 'enterDetailsContinueButton',
      dataTestId: 'rp-button-enter-details-continue'
    },
    backButton: {
      name: 'enterDetailsBackButton',
      dataTestId: 'rp-button-enter-details-back'
    }
  },
  setPreferences: {
    currencyFrom: {
      name: 'currencyFrom',
      dataTestId: 'rp-select-currency-from'
    },
    currencyTo: {
      name: 'currencyTo',
      dataTestId: 'rp-select-currency-to'
    },
    amount: {
      name: 'amount',
      dataTestId: 'rp-select-amount'
    },
    agreeTC: {
      name: 'agreeTC',
      dataTestId: 'rp-checkbox-agree-tc'
    },
    continueButton: {
      name: 'setPreferencesContinueButton',
      dataTestId: 'rp-button-set-preferences-continue'
    },
    backButton: {
      name: 'setPreferencesBackButton',
      dataTestId: 'rp-button-set-preferences-back'
    }
  },
  taxStatement: {
    continueButton: {
      name: 'taxStatementContinueButton',
      dataTestId: 'rp-button-tax-statement-continue'
    },
    backButton: {
      name: 'taxStatementBackButton',
      dataTestId: 'rp-button-tax-statement-back'
    }
  },
  confirmingYou: {
    kycFail: {
      documentUpload: {
        name: 'documentUpload',
        dataTestId: 'rp-radio-button-document-upload'
      },
      documentRequirements: {
        dataTestId: 'rp-link-document-requirements'
      },
      uploadDocumentsLater: {
        dataTestId: 'rp-link-upload-documents-later'
      }
    },
    mobileUpload: {
      sendSMS: {
        name: 'mobileUploadSendSMSButton',
        dataTestId: 'rp-radio-mobile-upload-send-sms'
      },
      SMSSentSuccess: {
        closeWindowButton: {
          name: 'closeWindowButton',
          dataTestId: 'rp-button-close-window'
        },
        backButton: {
          name: 'backButton',
          dataTestId: 'rp-button-back'
        }
      }
    },
    desktopUpload: {
      poi: {
        name: 'poi',
        dataTestId: 'rp-select-poi'
      },
      poa: {
        name: 'poa',
        dataTestId: 'rp-select-poa'
      },
      submitDocumentsButton: {
        name: 'submitDocumentsButton',
        dataTestId: 'rp-button-submit-documents'
      }
    }
  },
  kycPass: {
    loginButton: {
      name: 'kycPassLoginButton',
      dataTestId: 'rp-kyc-pass-button-login'
    }
  }
};
