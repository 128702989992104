import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import RPLink from '../../atoms/RPLink';
import RPButton from '../../atoms/RPButton';
import SplitLayout from '../../organisms/SplitLayout';
import RPLoader from '../../atoms/RPLoader';
// Hooks
import { useRegisterDeviceAndGenerateToken, useGetReferralRewardByLocale } from '../../../core/hooks';
import useLanguageCookieAndIPCheck from '../../../core/hooks/useLanguageCookieAndIPCheck';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LoadingState } from '../../../core/types/ReduxStoreTypes';
// RTK Slice
import { getAuthorisationDetails } from '../../../redux/modules/authorisationDetailsSlice';
import { getReferenceData } from '../../../redux/modules/referenceDataSlice';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { getImage } from '../../../core/utils/ImageOrgData';
import { branchIODataType, initBranch } from '../../../core/utils/BranchIO';
import { formatReferralRewards } from '../../../core/utils/FormatReferralRewards';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
// Constants
import { EN_US, MASK_CLASS, ORGANIZATION_CODE_MAPPING } from '../../../core/utils/Constants/Constants';

interface StyledContainerProps extends LayoutProps {}

interface StyledNameHeadingProps extends LayoutProps {}

interface StyledSubHeadingTextProps extends LayoutProps {}

interface StyledDescriptionTextProps extends LayoutProps {}

interface StyledRPLinkProps extends LayoutProps {}

const StyledContainer = styled(Box)<StyledContainerProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: isMobileLayout ? 'center' : 'flex-start',
    ...(isMobileLayout && {
      width: '325px'
    }),
    '& > svg': {
      width: '235px',
      height: '222px',
      marginBottom: '   30px'
    }
  };
});

const StyledNameHeading = styled(RPText)<StyledNameHeadingProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textActiveDark, textActiveDarkReferAFriend }
    }
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const COLOUR_TEXT_ACTIVE_DARK: HexColour = textActiveDark;
  const COLOUR_TEXT_ACTIVE_DARK_REFER_A_FRIEND: HexColour = textActiveDarkReferAFriend;

  return {
    color: isMobileLayout ? COLOUR_TEXT_ACTIVE_DARK : COLOUR_TEXT_ACTIVE_DARK_REFER_A_FRIEND,
    marginBottom: '8px',
    ...(isMobileLayout && {
      textAlign: 'center'
    })
  };
});

const StyledImageContainer = styled('img')<LayoutProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    width: isMobileLayout ? '235px' : '100%',
    maxWidth: '540px'
  };
});

const StyledSubHeadingText = styled(RPText)<StyledSubHeadingTextProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text, textLandingPage }
    }
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const COLOUR_TEXT_TEXT: HexColour = text;
  const COLOUR_TEXT_TEXT_LANDING_PAGE: HexColour = textLandingPage;

  return {
    color: isMobileLayout ? COLOUR_TEXT_TEXT : COLOUR_TEXT_TEXT_LANDING_PAGE,
    marginBottom: isMobileLayout ? '10px' : '32px',
    ...(isMobileLayout && {
      textAlign: 'center'
    })
  };
});

const StyledDescriptionText = styled(RPText)<StyledDescriptionTextProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text, textLandingPage }
    }
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const COLOUR_TEXT_TEXT: HexColour = text;
  const COLOUR_TEXT_TEXT_LANDING_PAGE: HexColour = textLandingPage;

  return {
    color: isMobileLayout ? COLOUR_TEXT_TEXT : COLOUR_TEXT_TEXT_LANDING_PAGE,
    marginBottom: '16px',
    ...(isMobileLayout && {
      textAlign: 'center'
    })
  };
});

const StyledRPLink = styled(RPLink)<StyledRPLinkProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '30px' : '40px',
    ...(isMobileLayout && {
      textAlign: 'center'
    })
  };
});

const ReferAFriend = () => {
  const navigate = useNavigate();
  let rafPageLoadRef = useRef(false);

  const { registerDeviceLoading, generateTokenLoading } = useSelector(getAuthorisationDetails);
  const { referralRewardsLoading, referralRewardsData, selectedLanguage } = useSelector(getReferenceData);

  const { translations, language } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { tenant } = useContext(TenantContext);

  useLanguageCookieAndIPCheck();
  useRegisterDeviceAndGenerateToken();

  const LandingPageIcon = useMemo(() => getImage(tenant, 'landingPage'), [tenant]);
  const LandingPageUSAIcon = useMemo(() => getImage(tenant, 'landingPageUSA'), [tenant]);

  const LandingPageImg = <StyledImageContainer src={LandingPageIcon} alt="Landing page image" layout={layout} />;
  const LandingPageUSAImg = (
    <StyledImageContainer src={LandingPageUSAIcon} alt="Landing page USA image" layout={layout} />
  );

  const [branchIOData, setBranchIOData] = useState<branchIODataType>({
    refererName: '',
    referralID: '',
    branchURL: '',
    source: '',
    subSource: '',
    legalEntity: ''
  });
  const [branchIOLoadingStatus, setBranchIOLoadingStatus] = useState<LoadingState>('idle');

  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const isCurrentLangUS: boolean = language === EN_US;
  const isCurrentTenantCD: boolean = tenant === ORGANIZATION_CODE_MAPPING.currenciesdirect;

  const bannerImage: JSX.Element = isCurrentLangUS && isCurrentTenantCD ? LandingPageUSAImg : LandingPageImg;

  const isReferralLinkExpired: boolean = branchIOLoadingStatus === 'failed';

  const isLoading: boolean =
    registerDeviceLoading === 'loading' ||
    generateTokenLoading === 'loading' ||
    branchIOLoadingStatus === 'loading' ||
    referralRewardsLoading === 'loading';

  const nameHeadingVariant: 'display-medium-norm' | 'display-large-bold' = isMobileLayout
    ? 'display-medium-norm'
    : 'display-large-bold';
  const headingTextVariant: 'h3' | 'h2' = isMobileLayout ? 'h3' : 'h2';
  const descriptionTextVariant: 'body1' | 'body2' = isDesktopLayout ? 'body1' : 'body2';
  const linkTextVariant: 'body1' | 'body2' = isDesktopLayout ? 'body1' : 'body2';
  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';

  const {
    eventAction: { click, load },
    referAFriendPage: {
      click: { rafOpenAccountCTAClicked, rafTermsAndConditionsClicked },
      load: { referFriendPageLoad }
    }
  } = GoogleAnalyticsTagsMapping;

  const referralRewardsValues = formatReferralRewards(
    referralRewardsData,
    referralRewardsLoading,
    tenant!,
    selectedLanguage
  );

  const subHeading: string = translate('referAFriendPage.subHeadingText');
  const description: string = translate('referAFriendPage.descriptionText', referralRewardsValues);
  const termsAndConditionsLinkText: string = translate('referAFriendPage.termsAndConditionsLinkText');
  const openFreeAccountButtonText: string = translate('referAFriendPage.openFreeAccountButtonText');
  const expiredHeadingText: string = translate('referAFriendPage.expiredHeadingText');
  const expiredSubHeadingText: string = translate('referAFriendPage.expiredSubHeadingText');
  const expiredDescriptionText: string = translate('referAFriendPage.expiredDescriptionText');
  const rafTncLink: string = translate('common.referAFriend.tncLink');

  const subHeadingText = isReferralLinkExpired ? expiredSubHeadingText : subHeading;
  const descriptionText = isReferralLinkExpired ? expiredDescriptionText : description;

  const headingText: string = isReferralLinkExpired ? expiredHeadingText : branchIOData.refererName;

  useEffect(() => {
    if (branchIOLoadingStatus === 'idle') {
      initBranch(setBranchIOData, setBranchIOLoadingStatus, tenant);
    }
  }, [branchIOLoadingStatus]);

  useEffect(() => {
    if (branchIOLoadingStatus === 'succeeded' && !rafPageLoadRef.current) {
      rafPageLoadRef.current = true;

      const { referralID, branchURL } = branchIOData;

      const dataLayerPayload: { [key: string]: string } = {
        referral_user_id: referralID,
        branch_io_url: branchURL
      };
      TriggerGoogleAnalyticsTag(load, referFriendPageLoad, {}, dataLayerPayload);
    }
  }, [branchIOLoadingStatus, generateTokenLoading]);

  const shouldShowRefererInfo = branchIOLoadingStatus === 'succeeded';
  useGetReferralRewardByLocale(branchIOData.legalEntity, shouldShowRefererInfo);

  const openAccountClickHandler = () => {
    TriggerGoogleAnalyticsTag(click, rafOpenAccountCTAClicked);
    navigate('/register-page');
  };

  const termsAndConditionsClickHandler = () => {
    TriggerGoogleAnalyticsTag(click, rafTermsAndConditionsClicked);
    window.open(rafTncLink, '_blank');
  };

  return (
    <SplitLayout isLandingPage={true} isReferAFriendPage={true} layoutVariant={'full'}>
      {isLoading ? (
        <RPLoader size="medium" type="secondary" />
      ) : (
        <StyledContainer layout={layout}>
          {isMobileLayout && bannerImage}
          <StyledNameHeading
            variant={'h1'}
            type={nameHeadingVariant}
            text={headingText}
            layout={layout}
            className={MASK_CLASS}
          />
          <StyledSubHeadingText variant={headingTextVariant} type={'bold'} text={subHeadingText} layout={layout} />
          <StyledDescriptionText
            variant={descriptionTextVariant}
            type={'normal'}
            text={descriptionText}
            layout={layout}
          />
          {!isReferralLinkExpired && (
            <>
              <StyledRPLink
                variant={linkTextVariant}
                type={'normal'}
                text={termsAndConditionsLinkText}
                onClick={termsAndConditionsClickHandler}
                data-testid="rp-link-refer-a-friend-tnc"
                layout={layout}
              />
              <RPButton
                size={buttonSize}
                type="button"
                name={openFreeAccountButtonText}
                data-testid={'rp-button-open-free-account'}
                onClick={openAccountClickHandler}
              >
                {openFreeAccountButtonText}
              </RPButton>
            </>
          )}
        </StyledContainer>
      )}
    </SplitLayout>
  );
};

export default ReferAFriend;
