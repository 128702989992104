import { createAsyncThunk } from '@reduxjs/toolkit';

// Types
import { AddressType } from '../../core/types/addressLookupTypes';
import { DoBType, ESPRegDetailsType, USARegDetailsType, BRARegDetailsType } from '../../core/types/RegistrationStepForm';
// RTK Slice
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
// API Wrappers
import axiosWrapper from './axiosWrapper';
// Utils
import GetBasicDetails from '../../core/utils/GetBasicDetails';

const { orgCodeID, orgCode, parentOrganizationCode, siteId } = GetBasicDetails();

type updateLeadPayloadData = {
  leadId: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  mobileNumber: string;
  countryOfResidence?: string;
  address?: AddressType;
  dob?: DoBType;
  occupation?: string;
  isFromEnterDetails?: boolean;
  usa?: USARegDetailsType | null;
  esp?: ESPRegDetailsType | null;
  bra?: BRARegDetailsType | null; // For Brazil country
};

const updateLead = createAsyncThunk('registration-service/v1/updateLead', async (data: updateLeadPayloadData, thunkAPI) => {
  const { firstName, lastName, leadId, countryCode, mobileNumber, countryOfResidence, address, dob, occupation, isFromEnterDetails, usa, esp, bra } = data;

  const { addressLine1, addressLine2, addressLine3, province, townCity, suburbCity, county, state, postcode } = address || {};

  const { socialSecurityNumber } = usa || {};
  const { nieNifTaxIdNumber } = esp || {};
  const { cpfTaxIdNumber } = bra || {};

  const detailsInfo = {
    countryofResidence: countryOfResidence,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    addressLine3: addressLine3,
    city: townCity,
    suburb: suburbCity,
    town: townCity,
    province: province,
    state: state,
    county: county,
    postcode: postcode,
    dobDay: dob?.day,
    dobMonth: dob?.month,
    dobYear: dob?.year,
    occupation: occupation
  };

  const USAData = {
    ssn: socialSecurityNumber
  };

  const ESPData = {
    nationalIdNumber: nieNifTaxIdNumber
  };

  const BRAData = {
    cpfTaxId: cpfTaxIdNumber
  };

  const payload = {
    leadId: leadId,
    organizationID: orgCodeID,
    siteID: siteId,
    organizationCode: orgCode,
    parentOrgCode: parentOrganizationCode,
    firstName: firstName,
    lastName: lastName,
    mobilePhone: `${countryCode}-${mobileNumber}`,
    ...(isFromEnterDetails && { ...detailsInfo }),
    ...(usa && { ...USAData }),
    ...(esp && { ...ESPData }),
    ...(bra && { ...BRAData })
  };
  try {
    const response = await axiosWrapper('updateLead', 'POST', thunkAPI, payload);

    return response && response.data;
  } catch (error: any) {
    thunkAPI.dispatch(getServicesErrorObj(error));
    return thunkAPI.rejectWithValue({ error: error.response.data.message });
  }
});

export default updateLead;
