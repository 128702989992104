import { useContext } from 'react';
import * as yup from 'yup';

import { LanguageContext } from '../../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';

export const ValidationSchema = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const agreeTCRequired: string = translate('registration.setPreferences.agreeTC.error.required');
  const taxDocRequired: string = translate('registration.taxStatement.file.error.required');

  return yup
    .object()
    .shape({
      agreeTC: yup.bool().required(agreeTCRequired).oneOf([true], agreeTCRequired),
      taxStatementDocument: yup.mixed().required(taxDocRequired)
    })
    .required();
};
