import { countryMetadata } from '../../CountryMetadata/CountryMetadata';
import { CountryMetadata } from '../../types/CountryMetadataTypes';
import { OptionType } from '../../types/SelectTypes';
import { OrgCodeTypes } from '../../types/OrganizationTypes';
import { LANG_CODE_MAPPING, ORG_LANG_MAPPING } from '../Constants/Constants';

export const getLanguageOptions = (
  orgCode: OrgCodeTypes
): OptionType[] | [] => {
  const currentLangMapping: Array<string> = ORG_LANG_MAPPING[orgCode];

  return currentLangMapping.length > 0
    ? countryMetadata.reduce(
        (data: OptionType[], currentObj: CountryMetadata) =>
          currentObj.languageCode &&
          ORG_LANG_MAPPING[orgCode].includes(currentObj.languageCode)
            ? (data.push({
                value:
                  LANG_CODE_MAPPING[
                    currentObj.languageCode ? currentObj.languageCode : 'EN'
                  ],
                label:
                  currentObj.languageCode === 'US'
                    ? 'EN'
                    : currentObj.languageCode === 'BR'
                    ? 'PT'
                    : currentObj.languageCode,
                iconPath: currentObj.icon,
              }),
              data)
            : data,
        []
      )
    : [];
};

/*
export const getLanguageValueFromCode = (languageCode: string) => {
  let languageValue: string = '';

  Object.keys(LANG_CODE_MAPPING).forEach((key) => {
    if (LANG_CODE_MAPPING[key] === languageCode) {
      languageValue = key;
    }
  });

  return languageValue;
};*/
