const GetCPFNumberFormatted = (value: string): string => {
  let inputValue = value.replace(/\D/g, ''); // Remove non-numeric characters

  if (inputValue.length > 9) {
    inputValue =
      inputValue.slice(0, 3) +
      '.' +
      inputValue.slice(3, 6) +
      '.' +
      inputValue.slice(6, 9) +
      '-' +
      inputValue.slice(9, 11);
  } else if (inputValue.length > 6) {
    inputValue = inputValue.slice(0, 3) + '.' + inputValue.slice(3, 6) + '.' + inputValue.slice(6);
  } else if (inputValue.length > 3) {
    inputValue = inputValue.slice(0, 3) + '.' + inputValue.slice(3);
  }
  return inputValue;
};

export default GetCPFNumberFormatted;
